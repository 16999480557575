import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageModalComponent } from './message-modal/message-modal.component';
import { SpinnerCardComponent } from './spinner-card/spinner-card.component';
import { SpinnerModalComponent } from './spinner-modal/spinner-modal.component';
import { MessageToastComponent } from './message-toast/message-toast.component';
import { CheckDropdownComponent } from './check-dropdown/check-dropdown.component';
import { FilterSelectionComponent } from './filter-selection/filter-selection.component';
import { BadgeListComponent } from './badge-list/badge-list.component';
import { MultipleSelectComponent } from './multiple-select/multiple-select.component';
import { TooltipDirective } from './tooltip.directive';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
    declarations: [
        MessageModalComponent,
        SpinnerCardComponent,
        SpinnerModalComponent,
        MessageToastComponent,
        CheckDropdownComponent,
        FilterSelectionComponent,
        BadgeListComponent,
        MultipleSelectComponent,
        TooltipDirective,
        DropdownComponent
    ],
    imports: [
        CommonModule,
        NgScrollbarModule
    ],
    exports: [
        MessageModalComponent,
        SpinnerCardComponent,
        SpinnerModalComponent,
        MessageToastComponent,
        CheckDropdownComponent,
        FilterSelectionComponent,
        BadgeListComponent,
        MultipleSelectComponent,
        TooltipDirective,
        DropdownComponent
    ]
})
export class AbqCommonModule {
}
