import { OrganismCode } from '../../../models/organism-code';

export class OrganismCodeResponse {
    success: boolean;
    errors: string;
    organismCodes: OrganismCode[];

    constructor(organismCodeServiceDTO: OrganismCodeServiceDTO) {
        this.success = organismCodeServiceDTO.success;
        this.errors = organismCodeServiceDTO.errors;
        this.organismCodes = organismCodeServiceDTO.organism_codes.map(
            (organismCodeDTO: OrganismCodeDTO) => new OrganismCode(
                organismCodeDTO.id,
                organismCodeDTO.code,
                organismCodeDTO.indication,
                organismCodeDTO.scientific_name));
    }
}

export interface OrganismCodeDTO {
    id: number;
    code: string;
    indication: number;
    scientific_name: string;
}

export interface OrganismCodeServiceDTO {
    success: boolean;
    errors: string;
    organism_codes: OrganismCodeDTO[];
}
