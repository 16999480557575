import { GetCountriesResponse } from '@shared/services/http/user-catalog/user-catalog-types';
import { RegisterComponentChildViews } from './register-component-child-views';
import { UserCatalogService } from '@shared/services/http/user-catalog/user-catalog.service';

export class CountryListGetter {

    constructor(private userCatalogService: UserCatalogService,
                private registerComponentChildViews: RegisterComponentChildViews) {
    }

    public getCountries() {
        this.userCatalogService
            .getCountries$()
            .subscribe({
                next: this.manageGetCountriesResponse.bind(this),
                error: this.manageGetCountriesErrors.bind(this)
            });
    }

    private manageGetCountriesResponse(response: GetCountriesResponse) {
        if (response.success) {
            this.registerComponentChildViews.updateCountries(response.countries);
        } else {
            console.log('Error received getting countries: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageGetCountriesErrors(error: any) {
        console.log('Unexpected error received getting countries: ' + JSON.stringify(error));
    }
}
