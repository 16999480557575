import { TrialCatalogService } from '@shared/services/http/trial-catalog/trial-catalog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TrialCooperatorsObtainerUpdater } from './trial-cooperators-obtainer-updater';
import { GetTrialCooperatorsResponse } from '@shared/services/http/trial-catalog/get-trial-cooperators-response';

export class TrialCooperatorsObtainer {
    constructor(
        private trialService: TrialCatalogService,
        private trialCooperatorsObtainerUpdater: TrialCooperatorsObtainerUpdater) {
    }

    public obtainTrialCooperators() {
        this.trialService.getTrialCooperators$().subscribe({
            next: this.manageObtainTrialCooperatorsResponse.bind(this),
            error: this.manageObtainTrialCooperatorsErrors.bind(this)
        });
    }

    private manageObtainTrialCooperatorsResponse(response: GetTrialCooperatorsResponse) {
        if (response.success) {
            this.trialCooperatorsObtainerUpdater.updateGetTrialCooperatorsResponse(response.trialCooperators);
        } else {
            console.log('Error received obtaining trial cooperators: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageObtainTrialCooperatorsErrors(error: HttpErrorResponse) {
        console.log('Unexpected error received obtaining trial cooperators: ' + JSON.stringify(error));
    }
}
