export class Indication {
    private readonly code: string;
    private readonly name: string;
    private readonly id: number;

    constructor(code: string, name: string, id: number) {
        this.code = code;
        this.name = name;
        this.id = id;
    }

    public getCode(): string {
        return this.code;
    }

    public getName(): string {
        return this.name;
    }

    public getId(): number {
        return this.id;
    }
}
