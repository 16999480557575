import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CropType } from '@shared/models/crop-type';
import { OrganismCode } from '@shared/models/organism-code';
import { CountryService } from '@shared/services/http/country/country.service';
import { CropTypeService } from '@shared/services/http/crop-type/crop-type.service';
import { OrganismCodeService } from '@shared/services/http/organism-code/organism-code.service';
import { TrialCatalogService } from '@shared/services/http/trial-catalog/trial-catalog.service';
import { from } from 'rxjs';
import { OrganismCodesObtainer } from '../../logged/abq-common/organism-codes-obtainer';
import { OrganismCodesUpdater } from '../../logged/abq-common/organism-codes-updater';
import {
    SavedFilterFieldValues
} from '../../logged/trial-selector/components/saved-filter-editor/saved-filter-field-values';
import { TrialFilterExtractor } from './trial-filter-extractor';
import { TrialYearListGenerator } from '../../logged/trial-selector/components/trial-year-list-generator';
import { AssessmentUses } from '../type-list';
import { CropTypesObtainer } from './crop-types-obtainer';
import { CropTypesUpdater } from './crop-types-updater';
import { TrialCooperatorsObtainer } from './trial-cooperators-obtainer';
import { TrialCooperatorsObtainerUpdater } from './trial-cooperators-obtainer-updater';
import { TrialGroupsObtainer } from './trial-groups-obtainer';
import { TrialGroupsObtainerUpdater } from './trial-groups-obtainer-updater';
import { TrialProtocolOriginatorsObtainer } from './trial-protocol-originators-obtainer';
import { TrialProtocolOriginatorsObtainerUpdater } from './trial-protocol-originators-obtainer-updater';
import { TrialProtocolsObtainer } from './trial-protocols-obtainer';
import { TrialProtocolsObtainerUpdater } from './trial-protocols-obtainer-updater';
import { TrialResearchersObtainer } from './trial-researchers-obtainer';
import { TrialResearchersObtainerUpdater } from './trial-researchers-obtainer-updater';
import { TrialSitesObtainer } from './trial-sites-obtainer';
import { TrialSitesObtainerUpdater } from './trial-sites-obtainer-updater';
import { TrialStudyReviewersObtainer } from './trial-study-reviewers-obtainer';
import { TrialStudyReviewersObtainerUpdater } from './trial-study-reviewers-obtainer-updater';
import { FilterFieldId } from './filter-selection.constants';
import { ElementInputtedInterface, ElementSelectedInterface, RemoveFilterValueInterface } from './filter.selection';
import { IndicationObtainerService } from '@shared/services/indication/indication-obtainer.service';
import { IndicationResponse } from '@shared/services/http/indication/indication-response';
import { IndicationList } from '@shared/models/indication-list';
import { TeamsService } from '@shared/services/team/teams.service';

@Component({
    selector: 'abq-filter-selection',
    templateUrl: './filter-selection.component.html',
    styleUrls: ['./filter-selection.component.scss']
})
export class FilterSelectionComponent implements OnInit, CropTypesUpdater, OrganismCodesUpdater,
    TrialGroupsObtainerUpdater, TrialProtocolsObtainerUpdater, TrialSitesObtainerUpdater,
    TrialCooperatorsObtainerUpdater, TrialProtocolOriginatorsObtainerUpdater, TrialResearchersObtainerUpdater,
    TrialStudyReviewersObtainerUpdater {

    @Input() shownFilters: FilterFieldId[];

    @Output() public allFilterValuesChange = new EventEmitter<SavedFilterFieldValues[]>();
    @Output() public applyFilter = new EventEmitter<void>();
    @Output() public clear = new EventEmitter<void>();
    @Output() public elementSelected = new EventEmitter<ElementSelectedInterface>();
    @Output() public elementInputted = new EventEmitter<ElementInputtedInterface>();
    @Output() public removeFilterValue = new EventEmitter<RemoveFilterValueInterface>();

    @ViewChild('filterNames') filterNamesRef;
    @ViewChild('newFilterName') newFilterNameRef;

    private readonly allFilterValues: SavedFilterFieldValues[];
    public EXPOSE_FILTER_FIELD_ID = FilterFieldId;

    public teams: SavedFilterFieldValues;
    public uses: SavedFilterFieldValues;
    public crops: SavedFilterFieldValues;
    public organisms: SavedFilterFieldValues;
    public trialTypes: SavedFilterFieldValues;
    public indications: SavedFilterFieldValues;
    public years: SavedFilterFieldValues;
    public originCountries: SavedFilterFieldValues;
    public protocols: SavedFilterFieldValues;
    public countries: SavedFilterFieldValues;
    public versions: SavedFilterFieldValues;
    public revisions: SavedFilterFieldValues;
    public sites: SavedFilterFieldValues;
    public numbers: SavedFilterFieldValues;
    public cooperators: SavedFilterFieldValues;
    public protocolOriginators: SavedFilterFieldValues;
    public researchers: SavedFilterFieldValues;
    public studyReviewers: SavedFilterFieldValues;

    private cropTypesObtainer: CropTypesObtainer;
    private organismCodesObtainer: OrganismCodesObtainer;
    private trialGroupsObtainer: TrialGroupsObtainer;
    private trialProtocolsObtainer: TrialProtocolsObtainer;
    private trialSitesObtainer: TrialSitesObtainer;
    private trialCooperatorsObtainer: TrialCooperatorsObtainer;
    private trialProtocolOriginatorsObtainer: TrialProtocolOriginatorsObtainer;
    private trialResearchersObtainer: TrialResearchersObtainer;
    private trialStudyReviewersObtainer: TrialStudyReviewersObtainer;

    constructor(private cropTypeService: CropTypeService,
                private organismCodeService: OrganismCodeService,
                private countryService: CountryService, trialService: TrialCatalogService,
                private indicationObtainerService: IndicationObtainerService,
                private teamsService: TeamsService
    ) {
        this.cropTypesObtainer = new CropTypesObtainer(this.cropTypeService, this);
        this.organismCodesObtainer = new OrganismCodesObtainer(this.organismCodeService, this);
        this.trialGroupsObtainer = new TrialGroupsObtainer(trialService, this);
        this.trialProtocolsObtainer = new TrialProtocolsObtainer(trialService, this);
        this.trialSitesObtainer = new TrialSitesObtainer(trialService, this);
        this.trialCooperatorsObtainer = new TrialCooperatorsObtainer(trialService, this);
        this.trialProtocolOriginatorsObtainer = new TrialProtocolOriginatorsObtainer(trialService, this);
        this.trialResearchersObtainer = new TrialResearchersObtainer(trialService, this);
        this.trialStudyReviewersObtainer = new TrialStudyReviewersObtainer(trialService, this);

        this.allFilterValues = [];
        const filterExtractor = new TrialFilterExtractor();
        for (let i = 0; i < FilterFieldId.End; i += 1) {
            this.allFilterValues[i] = new SavedFilterFieldValues(filterExtractor.getBackendName(i));
        }

        this.teams = this.allFilterValues[FilterFieldId.Team];
        this.uses = this.allFilterValues[FilterFieldId.Use];
        this.crops = this.allFilterValues[FilterFieldId.Crop];
        this.organisms = this.allFilterValues[FilterFieldId.Organism];
        this.trialTypes = this.allFilterValues[FilterFieldId.Type];
        this.indications = this.allFilterValues[FilterFieldId.Indication];
        this.years = this.allFilterValues[FilterFieldId.Year];
        this.originCountries = this.allFilterValues[FilterFieldId.OriginCountry];
        this.protocols = this.allFilterValues[FilterFieldId.Protocol];
        this.countries = this.allFilterValues[FilterFieldId.Country];
        this.versions = this.allFilterValues[FilterFieldId.Version];
        this.revisions = this.allFilterValues[FilterFieldId.Revision];
        this.sites = this.allFilterValues[FilterFieldId.Site];
        this.numbers = this.allFilterValues[FilterFieldId.Number];
        this.cooperators = this.allFilterValues[FilterFieldId.Cooperator];
        this.protocolOriginators = this.allFilterValues[FilterFieldId.ProtocolOriginator];
        this.researchers = this.allFilterValues[FilterFieldId.TrialResearcher];
        this.studyReviewers = this.allFilterValues[FilterFieldId.StudyReviewer];
    }

    ngOnInit(): void {
        from(this.allFilterValues).subscribe(() => this.allFilterValuesChange.emit(this.allFilterValues));

        if (this.shownFilters.includes(FilterFieldId.Team)) {
            this.teamsService.getTeams$().subscribe((teams) => {
                for (const team of teams) {
                    this.allFilterValues[FilterFieldId.Team].addValue(team.id, team.name);
                }
            });
        }
        if (this.shownFilters.includes(FilterFieldId.Use)) {
            new AssessmentUses().getAll().forEach(
                (value) => {
                    this.allFilterValues[FilterFieldId.Use].addValue(value.id, value.name);
                }
            );
        }
        if (this.shownFilters.includes(FilterFieldId.Crop)) {
            this.cropTypesObtainer.obtainCropTypes();
        }
        if (this.shownFilters.includes(FilterFieldId.Organism)) {
            this.organismCodesObtainer.obtainOrganismCodes(undefined, undefined);
        }
        if (this.shownFilters.includes(FilterFieldId.Indication)) {
            this.indicationObtainerService.obtain$().subscribe(
                {
                    next: (indicationResponse: IndicationResponse) => {
                        if (indicationResponse.success) {
                            for (const indication of indicationResponse.indications) {
                                this.allFilterValues[FilterFieldId.Indication].addValue(
                                    indication.getId(),
                                    new IndicationList().getCodeFromName(indication.getName()));
                            }
                        } else {
                            console.log('There were errors getting indications: ' + indicationResponse.errors);
                        }
                    }
                }
            );
        }
        if (this.shownFilters.includes(FilterFieldId.Type)) {
            this.trialGroupsObtainer.obtainTrialGroups();
        }
        if (this.shownFilters.includes(FilterFieldId.Protocol)) {
            this.trialProtocolsObtainer.obtainTrialProtocols();
        }
        if (this.shownFilters.includes(FilterFieldId.Site)) {
            this.trialSitesObtainer.obtainTrialSites();
        }
        if (this.shownFilters.includes(FilterFieldId.Year)) {
            new TrialYearListGenerator().getNumberList().forEach(
                (value) => {
                    this.allFilterValues[FilterFieldId.Year].addValue(value, value.toString());
                }
            );
        }
        if (this.shownFilters.includes(FilterFieldId.Country)) {
            this.countryService.obtain$().subscribe({
                next: (countries: string[]) => {
                    for (let i = 0; i < countries.length; i += 1) {
                        this.allFilterValues[FilterFieldId.OriginCountry].addValue(i, countries[i]);
                        this.allFilterValues[FilterFieldId.Country].addValue(i, countries[i]);
                    }
                }
            });
        }

        if (this.shownFilters.includes(FilterFieldId.Cooperator)) {
            this.trialCooperatorsObtainer.obtainTrialCooperators();
        }
        if (this.shownFilters.includes(FilterFieldId.ProtocolOriginator)) {
            this.trialProtocolOriginatorsObtainer.obtainTrialProtocolOriginators();
        }
        if (this.shownFilters.includes(FilterFieldId.TrialResearcher)) {
            this.trialResearchersObtainer.obtainTrialResearchers();
        }
        if (this.shownFilters.includes(FilterFieldId.StudyReviewer)) {
            this.trialStudyReviewersObtainer.obtainTrialStudyReviewers();
        }
    }

    public onApplyFilter(): void {
        this.applyFilter.emit();
    }

    public onClear() {
        this.clear.emit();
    }

    public onElementInputted(value: string, values: SavedFilterFieldValues): void {
        this.elementInputted.emit({value, values});
    }

    public onElementSelected(elementId: number, values: SavedFilterFieldValues): void {
        this.elementSelected.emit({elementId, values});
    }

    public onRemoveFilterValue(filter: string | number, filterValues: SavedFilterFieldValues) {
        this.removeFilterValue.emit({filter, filterValues});
    }

    public updateCropTypesResponse(cropTypes: CropType[]): void {
        for (const cropType of cropTypes) {
            this.allFilterValues[FilterFieldId.Crop].addValue(cropType.getId(), cropType.getName());
        }
        this.allFilterValues[FilterFieldId.Crop].sort();
    }

    public updateOrganismCodes(organismCodes: OrganismCode[]): void {
        for (const organismCode of organismCodes) {
            this.allFilterValues[FilterFieldId.Organism].addValue(organismCode.getId(), organismCode.getCode());
        }
    }

    public updateGetTrialGroupsResponse(groups: string[]): void {
        for (let i = 0; i < groups.length; i += 1) {
            this.allFilterValues[FilterFieldId.Type].addValue(i, groups[i]);
        }
    }

    public updateGetTrialProtocolsResponse(protocols: string[]): void {
        for (let i = 0; i < protocols.length; i += 1) {
            this.allFilterValues[FilterFieldId.Protocol].addValue(i, protocols[i]);
        }
    }

    public updateGetTrialSitesResponse(sites: string[]): void {
        for (let i = 0; i < sites.length; i += 1) {
            this.allFilterValues[FilterFieldId.Site].addValue(i, sites[i]);
        }
    }

    public updateGetTrialCooperatorsResponse(cooperators: string[]): void {
        for (let i = 0; i < cooperators.length; i += 1) {
            this.allFilterValues[FilterFieldId.Cooperator].addValue(i, cooperators[i]);
        }
    }

    public updateGetTrialProtocolOriginatorsResponse(protocolOriginators: string[]): void {
        for (let i = 0; i < protocolOriginators.length; i += 1) {
            this.allFilterValues[FilterFieldId.ProtocolOriginator].addValue(i, protocolOriginators[i]);
        }
    }

    public updateGetTrialResearchersResponse(researchers: string[]): void {
        for (let i = 0; i < researchers.length; i += 1) {
            this.allFilterValues[FilterFieldId.TrialResearcher].addValue(i, researchers[i]);
        }
    }

    public updateGetTrialStudyReviewersResponse(studyReviewers: string[]): void {
        for (let i = 0; i < studyReviewers.length; i += 1) {
            this.allFilterValues[FilterFieldId.StudyReviewer].addValue(i, studyReviewers[i]);
        }
    }
}
