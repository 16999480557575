import { TrialCatalogService } from '@shared/services/http/trial-catalog/trial-catalog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TrialProtocolsObtainerUpdater } from './trial-protocols-obtainer-updater';
import { GetTrialProtocolsResponse } from '@shared/services/http/trial-catalog/get-trial-protocols-response';

export class TrialProtocolsObtainer {
    constructor(
        private trialService: TrialCatalogService,
        private trialProtocolsObtainerUpdater: TrialProtocolsObtainerUpdater) {
    }

    public obtainTrialProtocols() {
        this.trialService.getTrialProtocols$().subscribe({
            next: this.manageObtainTrialProtocolsResponse.bind(this),
            error: this.manageObtainTrialProtocolsErrors.bind(this)
        });
    }

    private manageObtainTrialProtocolsResponse(response: GetTrialProtocolsResponse) {
        if (response.success) {
            this.trialProtocolsObtainerUpdater.updateGetTrialProtocolsResponse(response.trialProtocols);
        } else {
            console.log('Error received obtaining trial protocols: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageObtainTrialProtocolsErrors(error: HttpErrorResponse) {
        console.log('Unexpected error received obtaining trial protocols: ' + JSON.stringify(error));
    }
}
