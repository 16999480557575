import { GeneralResponse, GeneralDTO } from '../general-response/general-response';

export class CountryResponse extends GeneralResponse {
    countries: string[];

    constructor(countryDTO: CountryDTO) {
        super(countryDTO);
        this.countries = countryDTO.countries;
    }
}

export interface CountryDTO extends GeneralDTO {
    countries: string[];
}
