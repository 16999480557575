import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganismCodeResponse, OrganismCodeServiceDTO } from './organism-code-response';
import { Indication } from '../../../models/indication';

@Injectable({
    providedIn: 'root'
})
export class OrganismCodeService {

    static readonly urlList = '/abaqus/rest_api/webapp/info/organism_codes/';

    constructor(private http: HttpClient) {
    }

    public obtainList$(cropId: number, indication: Indication): Observable<OrganismCodeResponse> {
        let params = new HttpParams();
        if (cropId) {
            params = params.set('crop', cropId.toString());
        }
        if (indication) {
            params = params.set('indication', indication.getId().toString());
        }
        return this.http.get<OrganismCodeServiceDTO>(OrganismCodeService.urlList, {params})
            .pipe(map((organismCodeServiceDTO: OrganismCodeServiceDTO) =>
                new OrganismCodeResponse(organismCodeServiceDTO)));
    }
}
