import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResetPasswordResponse } from './reset-password-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {

    private urlReset = '/abaqus/rest_api/webapp/auth/password_ask/';

    constructor(private http: HttpClient) {
    }

    resetPassword$(email: string): Observable<ResetPasswordResponse> {
        let params = new HttpParams();
        params = params.append('email', email);
        return this.http.get<ResetPasswordResponse>(this.urlReset, {params});
    }
}
