export class TypeListElement {
    public id: number;
    public name: string;
    public label?: string;
    public tableLabel?: string;
}

export class TypeList {
    protected elements: TypeListElement[] = [];

    public getAll(): TypeListElement[] {
        return this.elements;
    }

    protected getAttrFromAttr(origAttr: string, value: string|number, destAttr: string): any {
        const elemFound = this.elements.find(elem => elem[origAttr] === value);
        if (elemFound) {
            return elemFound[destAttr];
        }
    }

    public getTableLabelFromName(name: string): string {
        return this.getAttrFromAttr('name', name, 'tableLabel');
    }

    public getLabelFromName(name: string): string {
        return this.getAttrFromAttr('name', name, 'label');
    }

    public getIdFromName(name: string): number {
        return this.getAttrFromAttr('name', name, 'id');
    }

    public getNameFromId(id: number): string {
        return this.getAttrFromAttr('id', id, 'name');
    }
}

export class SensorTypes extends TypeList {
    static CAMERA = 1;
    static MOBILE = 2;
    static MULTISPECTRAL = 3;
    static NO_IMAGE = 4;
    static APP = 5;
    static UAV_RGB = 6;

    constructor() {
        super();
        this.elements = [
            {id: SensorTypes.CAMERA, name: 'CAMERA', label: 'Digital Camera', tableLabel: 'CAM'},
            {id: SensorTypes.MOBILE, name: 'MOBILE', label: 'Mobile Phone', tableLabel: 'SMP'},
            {id: SensorTypes.MULTISPECTRAL, name: 'MULTISPECTRAL', label: 'Multi spectral', tableLabel: ''},
            {id: SensorTypes.APP, name: 'APP', label: 'Abaqus Field App', tableLabel: 'APP'},
            {id: SensorTypes.UAV_RGB, name: 'UAV_RGB', label: 'UAV RGB', tableLabel: 'UAV RGB'}
        ];
    }

    public getMultiSpectralName(): string {
        return 'MULTISPECTRAL';
    }

    public getMultiSpectralId(): number {
        return this.getAttrFromAttr('name', this.getMultiSpectralName(), 'id');
    }
}

export class MultiSpectralBandTypes extends TypeList {
    constructor() {
        super();
        this.elements = [
            {id: 2, name: 'RED', label: 'Red', tableLabel: 'M-RED'},
            {id: 3, name: 'GREEN', label: 'Green', tableLabel: 'M-GREEN'},
            {id: 4, name: 'BLUE', label: 'Blue', tableLabel: 'M-BLUE'},
            {id: 5, name: 'RED_EDGE', label: 'Red edge', tableLabel: 'M-EDGE'},
            {id: 6, name: 'NEARIR', label: 'Near IR', tableLabel: 'M-NIR'},
        ];
    }
}

export enum AssessmentUse {
    TRAINING = 1,
    ASSESSMENT,
    DEVELOPMENT,
    MIXED
}

export class AssessmentUses extends TypeList {
    constructor() {
        super();
        this.elements = [
            {id: AssessmentUse.TRAINING, name: 'TRAINING'},
            {id: AssessmentUse.ASSESSMENT, name: 'ASSESSMENT'},
            {id: AssessmentUse.DEVELOPMENT, name: 'DEVELOPMENT'},
            {id: AssessmentUse.MIXED, name: 'MIXED'}
        ];
    }
}
