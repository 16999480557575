<div class="position-absolute w-100 p-4 d-flex flex-column align-items-end"
     style="top: 0; pointer-events: none; z-index: 1100;">
    <div class="w-25">
        <div #toast class="toast ml-auto" role="alert" data-delay="5000" data-autohide="true">
            <div class="toast-header">
                <strong class="mr-auto text-primary">AbaQus Field</strong>
                <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close"
                        style="pointer-events: all;">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="toast-body" [innerHTML]="toastMessage">
            </div>
        </div>
    </div>
</div>
