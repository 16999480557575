import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { AbqCommonModule } from '../abq-common/abq-common.module';
import { RegisterCheckComponent } from './register-check/register-check.component';
import { AppRoutingModule } from '../../app-routing.module';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordCheckComponent } from './reset-password-check/reset-password-check.component';
import { GenericLoginComponent } from './generic-login/generic-login.component';
import { LoginComponent } from './login/login.component';
import { LoggedShownComponent } from './logged-shown/logged-shown.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';


@NgModule({
    declarations: [
        GenericLoginComponent,
        LoginComponent,
        RegisterComponent,
        RegisterCheckComponent,
        ResetPasswordComponent,
        ResetPasswordCheckComponent,
        LoggedShownComponent,
        LoggedOutComponent,
        MaintenanceComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AbqCommonModule,
        AppRoutingModule
    ]
})
export class LoginModule {
}
