import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetTrialSitesResponse, GetTrialSitesDTO } from './get-trial-sites-response';
import { GetTrialGroupsResponse, GetTrialGroupsDTO } from './get-trial-groups-response';
import { GetTrialProtocolsResponse, GetTrialProtocolsDTO } from './get-trial-protocols-response';
import { map } from 'rxjs/operators';
import { GetTrialCooperatorsResponse, GetTrialCooperatorsDTO } from './get-trial-cooperators-response';
import {
    GetTrialProtocolOriginatorsResponse,
    GetTrialProtocolOriginatorsDTO
} from './get-trial-protocol-originators-response';
import { GetTrialResearchersResponse, GetTrialResearchersDTO } from './get-trial-researchers-response';
import {
    GetTrialStudyReviewersResponse,
    GetTrialStudyReviewersDTO
} from './get-trial-study-reviewers-response';
import { GetTrialPlotsResponse, GetTrialPlotsDTO } from './get-trial-plots-response';

@Injectable({
    providedIn: 'root'
})
export class TrialCatalogService {

    private urlGetTrialSites = '/abaqus/rest_api/webapp/trial/sites/';
    private urlGetTrialGroups = '/abaqus/rest_api/webapp/trial/groups/';
    private urlGetTrialProtocols = '/abaqus/rest_api/webapp/trial/protocols/';
    private urlGetTrialCooperators = '/abaqus/rest_api/webapp/trial/cooperators/';
    private urlGetTrialOriginators = '/abaqus/rest_api/webapp/trial/protocol_originators/';
    private urlGetTrialResearchers = '/abaqus/rest_api/webapp/trial/researchers/';
    private urlGetTrialStudyReviewers = '/abaqus/rest_api/webapp/trial/study_reviewers/';
    private urlGetTrialPlots = '/abaqus/rest_api/webapp/trial/plots/';

    constructor(private http: HttpClient) {
    }

    public getTrialSites$(): Observable<GetTrialSitesResponse> {
        return this.http.get<GetTrialSitesDTO>(this.urlGetTrialSites)
            .pipe(map((getTrialSitesDTO: GetTrialSitesDTO) => new GetTrialSitesResponse(getTrialSitesDTO)));
    }

    public getTrialGroups$(): Observable<GetTrialGroupsResponse> {
        return this.http.get<GetTrialGroupsDTO>(this.urlGetTrialGroups)
            .pipe(map((getTrialGroupsDTO: GetTrialGroupsDTO) => new GetTrialGroupsResponse(getTrialGroupsDTO)));
    }

    public getTrialProtocols$(): Observable<GetTrialProtocolsResponse> {
        return this.http.get<GetTrialProtocolsDTO>(this.urlGetTrialProtocols)
            .pipe(map((getTrialProtocolsDTO: GetTrialProtocolsDTO) =>
                new GetTrialProtocolsResponse(getTrialProtocolsDTO)));
    }

    public getTrialCooperators$(): Observable<GetTrialCooperatorsResponse> {
        return this.http.get<GetTrialCooperatorsDTO>(this.urlGetTrialCooperators)
            .pipe(map((getTrialCooperatorsDTO: GetTrialCooperatorsDTO) =>
                new GetTrialCooperatorsResponse(getTrialCooperatorsDTO)));
    }

    public getTrialProtocolOriginators$(): Observable<GetTrialProtocolOriginatorsResponse> {
        return this.http.get<GetTrialProtocolOriginatorsDTO>(this.urlGetTrialOriginators)
            .pipe(map((getTrialProtocolOriginatorsDTO: GetTrialProtocolOriginatorsDTO) =>
                new GetTrialProtocolOriginatorsResponse(getTrialProtocolOriginatorsDTO)));
    }

    public getTrialResearchers$(): Observable<GetTrialResearchersResponse> {
        return this.http.get<GetTrialResearchersDTO>(this.urlGetTrialResearchers)
            .pipe(map((getTrialResearchersDTO: GetTrialResearchersDTO) =>
                new GetTrialResearchersResponse(getTrialResearchersDTO)));
    }

    public getTrialStudyReviewers$(): Observable<GetTrialStudyReviewersResponse> {
        return this.http.get<GetTrialStudyReviewersDTO>(this.urlGetTrialStudyReviewers)
            .pipe(map((getTrialStudyReviewersDTO: GetTrialStudyReviewersDTO) =>
                new GetTrialStudyReviewersResponse(getTrialStudyReviewersDTO)));
    }

    public getTrialPlots$(trialId: number): Observable<GetTrialPlotsResponse> {
        const params = new HttpParams().set('trial', trialId.toString());

        return this.http.get<GetTrialPlotsDTO>(this.urlGetTrialPlots, {params})
            .pipe(map((getTrialPlotsDTO: GetTrialPlotsDTO) =>
                new GetTrialPlotsResponse(getTrialPlotsDTO)));
    }

}
