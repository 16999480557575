<div #checkDropdown class="btn-group">
    <button class="btn btn-outline-primary btn-sm mx-1 py-0 dropdown-toggle btn-block" type="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="disabled">
        <i class="fa {{ icon }}"></i>
        {{ name }}
    </button>
    <div class="dropdown-menu">
        <div *ngIf="searchBox === true" class="input-group px-2">
            <span class="input-group-prepend w-100">
                <i class="fa fa-search position-absolute"></i>
                <!--suppress HtmlFormInputWithoutLabel -->
                <input #myInput id="search-input" class="form-control py-2 border" placeholder="Search..."
                       (input)="searchString( myInput.value )">
           </span>
        </div>
        <ng-scrollbar class="scrollable-menu position-relative"
                      [track]="'vertical'" [pointerEventsMethod]="'scrollbar'"
                      [autoHeightDisabled]="false" [autoWidthDisabled]="false"
                      (click)="$event.stopPropagation()">
            <button *ngIf="allElement === true" class="dropdown-item py-1"
                    (click)="allClicked()">
                <i class="fa fa-check text-primary mr-2" *ngIf="isAllSelected"></i>
                All
            </button>
            <ng-container *ngFor="let value of elements">
                <button *ngIf="!value.hidden" class="dropdown-item py-1"
                        (click)="elementClicked( value )">
                    <i class="fa fa-check text-primary mr-2" *ngIf="selected[ value.id ?? value ]"></i>
                    {{ getValue(value) }}
                </button>
            </ng-container>
        </ng-scrollbar>
        <div *ngIf="applyButton === true">
            <button class="btn btn-primary btn-sm mx-2 py-0 float-right" type="button"
                    [ngClass]="{ 'cursor-default': applyButtonDisabled }" [disabled]="applyButtonDisabled"
                    (click)="applyChanges()">
                <i class="fa fa-sync"></i>
                Apply
            </button>
        </div>
    </div>
</div>
