import { TrialCatalogService } from '@shared/services/http/trial-catalog/trial-catalog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TrialProtocolOriginatorsObtainerUpdater } from './trial-protocol-originators-obtainer-updater';
import {
    GetTrialProtocolOriginatorsResponse
} from '@shared/services/http/trial-catalog/get-trial-protocol-originators-response';

export class TrialProtocolOriginatorsObtainer {
    constructor(
        private trialService: TrialCatalogService,
        private trialProtocolOriginatorsObtainerUpdater: TrialProtocolOriginatorsObtainerUpdater) {
    }

    public obtainTrialProtocolOriginators() {
        this.trialService.getTrialProtocolOriginators$().subscribe({
            next: this.manageObtainTrialProtocolOriginatorsResponse.bind(this),
            error: this.manageObtainTrialProtocolOriginatorsErrors.bind(this)
        });
    }

    private manageObtainTrialProtocolOriginatorsResponse(response: GetTrialProtocolOriginatorsResponse) {
        if (response.success) {
            this.trialProtocolOriginatorsObtainerUpdater
                .updateGetTrialProtocolOriginatorsResponse(response.trialProtocolOriginators);
        } else {
            console.log('Error received obtaining trial protocol originators: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageObtainTrialProtocolOriginatorsErrors(error: HttpErrorResponse) {
        console.log('Unexpected error received obtaining trial protocol originators: ' + JSON.stringify(error));
    }

}
