import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponse } from './login-response';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    private urlLogin: string;

    constructor(private http: HttpClient) {
    }

    public setUrlLogin(urlLogin: string) {
        this.urlLogin = urlLogin;
    }

    public login$(username: string = '', password: string = ''): Observable<LoginResponse> {
        const formData = new FormData();
        formData.set('username', username);
        formData.set('password', password);

        return this.http.post<LoginResponse>(this.urlLogin, formData);
    }
}
