import { CropType } from '../../../models/crop-type';

export class CropTypeResponse {
    success: boolean;
    errors: string;
    cropTypes: CropType[];

    constructor(cropTypeServiceDTO: CropTypeServiceDTO) {
        this.success = cropTypeServiceDTO.success;
        this.errors = cropTypeServiceDTO.errors;
        this.cropTypes = cropTypeServiceDTO.crop_types.map(
            (cropTypeDTO: CropTypeDTO) => new CropType(cropTypeDTO.id, cropTypeDTO.name));
    }
}

export interface CropTypeDTO {
    id: number;
    name: string;
}

export interface CropTypeServiceDTO {
    success: boolean;
    errors: string;
    crop_types: CropTypeDTO[];
}
