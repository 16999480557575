export class GeneralResponse {
    readonly success: boolean;
    readonly errors: string;
    readonly formErrors: Map<string, string[]>;

    constructor(generalDTO: GeneralDTO) {
        this.success = generalDTO.success;
        if (generalDTO.errors) {
            this.errors = generalDTO.errors;
        }
        if (generalDTO.form_errors) {
            this.formErrors = new Map<string, string[]>();
            for (const [key, valueList] of Object.entries(generalDTO.form_errors)) {
                this.formErrors.set(key, valueList);
            }
        }
    }
}

export interface GeneralDTO {
    success: boolean;
    errors: string;
    form_errors: object;
}
