import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'abq-logged-out',
    templateUrl: './logged-out.component.html',
    styleUrls: ['./logged-out.component.scss']
})
export class LoggedOutComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
