import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'abq-badge-list',
    templateUrl: './badge-list.component.html',
    styleUrls: ['./badge-list.component.scss']
})
export class BadgeListComponent {

    @Input() public allElementsSelected = false;
    @Input() public icon: string;
    @Input() public deletable: boolean;
    @Input() public elements: string[]|number[];
    @Input() public showVertical = false;
    @Output() public removeClicked = new EventEmitter<string|number>();

    public remove(element: string|number) {
        this.removeClicked.emit(element);
    }

    getElementName(element) {
        if (element === '') {
            return '[ not defined ]';
        }

        return element;
    }
}
