import { GeneralDTO, GeneralResponse } from '@shared/services/http/general-response/general-response';

export class GetTrialSitesResponse extends GeneralResponse {

    trialSites: string[];

    constructor(getTrialSitesDTO: GetTrialSitesDTO) {
        super(getTrialSitesDTO);
        this.trialSites = getTrialSitesDTO.trial_sites;
    }
}

export interface GetTrialSitesDTO extends GeneralDTO {
    trial_sites: string[];
}
