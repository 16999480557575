import { HttpErrorResponse } from '@angular/common/http';
import { CropTypeService } from '@shared/services/http/crop-type/crop-type.service';
import { CropTypesUpdater } from './crop-types-updater';
import { CropTypeResponse } from '@shared/services/http/crop-type/crop-type-response';

export class CropTypesObtainer {
    constructor(
        private cropTypeService: CropTypeService,
        private cropTypesUpdater: CropTypesUpdater) {
    }

    public obtainCropTypes() {
        this.cropTypeService.obtainList$().subscribe({
            next: this.manageObtainCropTypesResponse.bind(this),
            error: this.manageObtainCropTypesErrors.bind(this)
        });
    }

    private manageObtainCropTypesResponse(response: CropTypeResponse) {
        if (response.success) {
            this.cropTypesUpdater.updateCropTypesResponse(response.cropTypes);
        } else {
            console.log('Error received obtaining crop types: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageObtainCropTypesErrors(error: HttpErrorResponse) {
        console.log('Unexpected error received obtaining crop types: ' + JSON.stringify(error));
    }
}
