import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'abq-multiple-select',
    templateUrl: './multiple-select.component.html',
    styleUrls: ['./multiple-select.component.scss']
})
export class MultipleSelectComponent implements OnInit, OnDestroy {
    @Input() set elements(elements: string[]) {
        if (elements) {
            elements.sort((elemA, elemB) => elemA.localeCompare(elemB));
            this.storedElements = elements;
            this.visibleElements = [...this.storedElements];
        }
    }

    @Input() name: string;
    @Input() disabled = false;
    @Input() setSelectionSubject: Subject<string[]> = new Subject<string[]>();
    @Input() searchBox = false;

    @Output() applyChangesSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

    public visibleElements: string[];
    private storedElements: string[];
    private selectedElements: string[];
    private unsubscriber$: Subject<void> = new Subject<void>();

    ngOnInit() {
        const dropdownSelector = $('.dropdown-toggle');
        dropdownSelector.dropdown();
        this.selectedElements = [];

        this.setSelectionSubject.pipe(takeUntil(this.unsubscriber$)).subscribe((elements: string[]) => {
            this.selectedElements = elements;
        });
    }

    ngOnDestroy() {
        this.unsubscriber$.next();
        this.unsubscriber$.complete();
    }

    isSelected(value: string) {
        return this.selectedElements.findIndex(selected => selected === value) !== -1;
    }

    elementClicked(value: string) {
        const valueIndex = this.selectedElements.findIndex(selected => selected === value);

        if (valueIndex !== -1) {
            this.selectedElements.splice(valueIndex, 1);
        } else {
            this.selectedElements.push(value);
        }
    }

    applyChanges() {
        this.applyChangesSelected.emit(this.selectedElements);
    }

    searchString(value: string) {
        this.visibleElements = [];
        for (const elem of this.storedElements) {
            if (value === '' || elem.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                this.visibleElements.push(elem);
            }
        }
    }
}
