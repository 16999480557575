import { HttpErrorResponse } from '@angular/common/http';
import { TrialCatalogService } from '@shared/services/http/trial-catalog/trial-catalog.service';
import { GetTrialGroupsResponse } from '@shared/services/http/trial-catalog/get-trial-groups-response';
import { TrialGroupsObtainerUpdater } from './trial-groups-obtainer-updater';

export class TrialGroupsObtainer {
    constructor(
        private trialService: TrialCatalogService,
        private trialGroupsObtainerUpdater: TrialGroupsObtainerUpdater) {
    }

    public obtainTrialGroups() {
        this.trialService.getTrialGroups$().subscribe({
            next: this.manageObtainTrialGroupsResponse.bind(this),
            error: this.manageObtainTrialGroupsErrors.bind(this)
        });
    }

    private manageObtainTrialGroupsResponse(response: GetTrialGroupsResponse) {
        if (response.success) {
            this.trialGroupsObtainerUpdater.updateGetTrialGroupsResponse(response.trialGroups);
        } else {
            console.log('Error received obtaining trial groups: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageObtainTrialGroupsErrors(error: HttpErrorResponse) {
        console.log('Unexpected error received obtaining trial groups: ' + JSON.stringify(error));
    }
}
