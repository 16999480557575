import { GetOrganizationsResponse } from '@shared/services/http/user-catalog/user-catalog-types';
import { RegisterComponentChildViews } from './register-component-child-views';
import { UserCatalogService } from '@shared/services/http/user-catalog/user-catalog.service';

export class OrganizationListGetter {

    constructor(private userCatalogService: UserCatalogService,
                private registerComponentChildViews: RegisterComponentChildViews) {
    }

    public getOrganizations() {
        this.userCatalogService
            .getOrganizations$()
            .subscribe({
                next: this.manageGetOrganizationsResponse.bind(this),
                error: this.manageGetOrganizationsErrors.bind(this)
            });
    }

    private manageGetOrganizationsResponse(response: GetOrganizationsResponse) {
        if (response.success) {
            this.registerComponentChildViews.updateOrganizations(response.organization_codes);
        } else {
            console.log('Error received getting organizations: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageGetOrganizationsErrors(error: any) {
        console.log('Unexpected error received getting organizations: ' + JSON.stringify(error));
    }
}
