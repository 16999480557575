import { Plot } from '@shared/models/plot';
import { GeneralDTO, GeneralResponse } from '@shared/services/http/general-response/general-response';

import { PlotDTO } from '@shared/services/http/plot-dto';

export class GetTrialPlotsResponse extends GeneralResponse {

    plots: Plot[] = [];

    constructor(getTrialPlotsDTO: GetTrialPlotsDTO) {
        super(getTrialPlotsDTO);
        for (const plotDTO of getTrialPlotsDTO.plots) {
            this.plots.push(Plot.createFromDTO(plotDTO));
        }
    }
}

export interface GetTrialPlotsDTO extends GeneralDTO {
    plots: PlotDTO[];
}

