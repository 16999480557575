import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'abq-spinner-card',
    templateUrl: './spinner-card.component.html',
    styleUrls: ['./spinner-card.component.scss']
})
export class SpinnerCardComponent {

    @Input() show: boolean;
    @Input() cancellable: boolean;
    @Input() cancelButtonText: string;
    @Output() cancelled = new EventEmitter<void>();

    constructor() {
        this.cancelButtonText = 'Cancel...';
    }

    public cancelSpinner() {
        this.cancelled.emit();
    }
}
