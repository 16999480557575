import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ChangePasswordResponse, CheckChangePasswordResponse } from './reset-password-check-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordCheckService {

    private urlCheckChangePassword = '/abaqus/rest_api/webapp/auth/password_check/';
    private urlChangePassword = '/abaqus/rest_api/webapp/auth/password/';

    constructor(private http: HttpClient) {
    }

    public checkChangePassword$(uid: string, token: string): Observable<CheckChangePasswordResponse> {
        let params = new HttpParams();
        if (uid) {
            params = params.append('uid_b64', uid);
        }
        if (token) {
            params = params.append('token', token);
        }
        return this.http.get<CheckChangePasswordResponse>(this.urlCheckChangePassword, {params});
    }

    public changePassword$(uid: string, token: string, password: string): Observable<ChangePasswordResponse> {
        let params = new HttpParams();
        if (uid) {
            params = params.append('uid_b64', uid);
        }
        if (token) {
            params = params.append('token', token);
        }
        if (password) {
            params = params.append('new_password', password);
        }
        return this.http.get<ChangePasswordResponse>(this.urlChangePassword, {params});
    }
}
