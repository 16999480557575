export interface MessageModalOptions {
    title: string;
    text: string;
    mode?: MessageModalMode;
    hideTimesButton?: boolean;
    detailText?: string;
    continueAction?: () => void;
    noAction?: () => void;
    yesAction?: () => void;
}

export enum MessageModalMode {
    CLOSE,
    CONTINUE,
    CONTINUE_CANCEL,
    YES_NO_CANCEL,
    YES_NO,
    ACCEPT
}
