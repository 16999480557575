<div class="container animated zoomIn fast">
    <div class="row">
        <div class="col">
            <div id="logo">
                <img src="assets/img/logoMaintenance.PNG" draggable="false" alt="LogoMaintenance">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="col text-center">
                        <h4>Sorry for the inconvenience, we&rsquo;ll be back soon!</h4>
                    </div>
                    <div class="col text-center">
                        <div class="form-group mt-3">
                          Some maintenance work is being carried out to improve our services. The website will be operational again soon.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <strong>AbaQus Field</strong> - Copyright 2019
        </div>
    </div>
</div>
