import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MessageModalMode, MessageModalOptions } from './message-modal-types';
import { MessageModalService } from './message-modal.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'abq-message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss']
})

export class MessageModalComponent implements AfterViewInit, OnDestroy {
    public messageModalMode = MessageModalMode;
    public options;
    @Input() public protected = null;
    @Input() public serviceMode = false;
    @ViewChild('messageModal') messageModalRef;
    private messageModal$;
    private subscription: Subscription;

    constructor(private messageModalService: MessageModalService) {
    }

    public ngAfterViewInit(): void {
        this.messageModal$ = $(this.messageModalRef.nativeElement);
        if (this.serviceMode) {
            this.subscribeToMessageModalService();
        }
    }

    private subscribeToMessageModalService() {
        this.subscription = this.messageModalService.onModalRequest$().subscribe({
            next: (messageModalOptions: MessageModalOptions) => {
                this.show(messageModalOptions);
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public show(options, appendTo?: string) {
        this.options = options;

        appendTo ? this.messageModal$.appendTo(appendTo).modal('show') :
            this.messageModal$.modal('show');
    }

    public hide() {
        this.messageModal$.modal('hide');
    }

    public doContinueAction() {
        if (this.options.continueAction) {
            this.options.continueAction();
            this.hide();
        }
    }

    public doNoAction() {
        if (this.options.noAction) {
            this.options.noAction();
            this.hide();
        }
    }

    public doYesAction() {
        if (this.options.yesAction) {
            this.options.yesAction();
            this.hide();
        }
    }
}
