import { Injectable } from '@angular/core';
import { UserCatalogService } from '@shared/services/http/user-catalog/user-catalog.service';
import { Observable, throwError } from 'rxjs';
import { TeamDTO } from '@shared/services/http/team-dto';
import { GetTeamsResponse } from '@shared/services/http/user-catalog/user-catalog-types';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TeamsService {
    static readonly UNEXPECTED_ERROR_MESSAGE = 'Unexpected error received getting teams: ';
    static readonly ERROR_IN_RESPONSE_MESSAGE = 'Error received getting teams: ';

    constructor(private userCatalogService: UserCatalogService) {
    }

    public getTeams$(): Observable<TeamDTO []> {
        return this.userCatalogService.getTeams$().pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                return throwError(new Error(TeamsService.UNEXPECTED_ERROR_MESSAGE + JSON.stringify(httpErrorResponse)));
            }),
            map((response: GetTeamsResponse) => {
                if (!response.success) {
                    throw new Error(TeamsService.ERROR_IN_RESPONSE_MESSAGE + response.errors);
                }
                return response.teams;
            })
        );
    }
}
