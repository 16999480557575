import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MessageModalOptions } from './message-modal-types';

@Injectable({
    providedIn: 'root'
})
export class MessageModalService {

    private messageModalOptionsSubject = new Subject<MessageModalOptions>();

    public onModalRequest$(): Observable<MessageModalOptions> {
        return this.messageModalOptionsSubject.asObservable();
    }

    public show(options: MessageModalOptions) {
        this.messageModalOptionsSubject.next(options);
    }
}
