<div class="container animated zoomIn fast">
    <div class="row">
        <div class="col">
            <div id="logo">
                <a routerLink="/">
                    <img src="assets/img/logo.svg" draggable="false" alt="Logo">
                </a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="col text-center mb-4">
                        <h5>AbaQus Field · Register new user</h5>
                    </div>
                    <div class="col">
                        <form [formGroup]="registerForm" (ngSubmit)="register()">
                            <div class="row form-group">
                                <label for="username" class="col-4 col-form-label">User ID:</label>
                                <input type="text" id="username" autocomplete="off"
                                       class="form-control col-sm-8" value="{{username}}" readonly/>
                            </div>
                            <div class="row form-group">
                                <label for="email-address" class="col-4 col-form-label">Email address:</label>
                                <input type="text" id="email-address" autocomplete="off"
                                       class="form-control col-sm-8" value="{{email}}" readonly/>
                            </div>
                            <div class="row form-group">
                                <label for="password" class="col-4 col-form-label">Desired password:</label>
                                <input type="password" formControlName="password" id="password" autocomplete="off"
                                       class="form-control col-sm-8" placeholder="insert password"
                                       [ngClass]="{ 'is-invalid': submitted && formCtr.password.errors }"/>
                                <div *ngIf="submitted && formCtr.password.errors" class="invalid-feedback offset-4">
                                    <div *ngIf="formCtr.password.errors.required">The desired password is required
                                    </div>
                                    <div *ngIf="formCtr.password.errors.server_error">
                                        {{ formCtr.password.errors.server_error }}</div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="organization" class="col-4 col-form-label">Organization code:</label>
                                <select id="organization" class="form-control col-sm-8" formControlName="organization_code"
                                        [ngClass]="{ 'is-invalid': submitted && formCtr.organization_code.errors }">
                                    <option disabled value="">- select organization code -</option>
                                    <option *ngFor="let organization of organizations" [value]="organization?.id">
                                        {{ organization?.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && formCtr.organization_code.errors" class="invalid-feedback offset-4">
                                    <div *ngIf="formCtr.organization_code.errors.required">The organization is required</div>
                                    <div *ngIf="formCtr.organization_code.errors.server_error">
                                        {{ formCtr.organization_code.errors.server_error }}</div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="country" class="col-4 col-form-label">Country:</label>
                                <select id="country" class="form-control col-sm-8" formControlName="country"
                                        [ngClass]="{ 'is-invalid': submitted && formCtr.country.errors }">
                                    <option disabled  value="">- select country -</option>
                                    <option *ngFor="let country of countries" value="{{ country.id }}">
                                        {{ country?.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && formCtr.country.errors" class="invalid-feedback offset-4">
                                    <div *ngIf="formCtr.country.errors.required">The country is required</div>
                                    <div *ngIf="formCtr.country.errors.server_error">
                                        {{ formCtr.country.errors.server_error }}</div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="date-format" class="col-4 col-form-label">Date format:</label>
                                <select id="date-format" class="col-4 form-control" formControlName="simple_date_format"
                                        [ngClass]="{ 'is-invalid': submitted && formCtr.simple_date_format.errors }">
                                    <option disabled value="">- select format -</option>
                                    <option *ngFor="let format of formats" value="{{ format.id }}">
                                        {{ format.name }}
                                    </option>
                                </select>
                                <select id="date-separator" class="col-4 form-control" formControlName="separator"
                                        [ngClass]="{ 'is-invalid': submitted && formCtr.separator.errors }">
                                    <option disabled value="" >- select separator -</option>
                                    <option *ngFor="let separator of separators" value="{{ separator.id }}">
                                        {{ separator.name }}
                                    </option>
                                </select>
                                <div class="custom-control custom-checkbox col-8 offset-4 mt-2">
                                    <input type="checkbox" class="custom-control-input" id="four-digit-year" formControlName="four_digit_year">
                                    <label class="custom-control-label" for="four-digit-year" style="font-weight: normal;">
                                        Four digit year</label>
                                </div>
                                <div *ngIf="submitted && ( formCtr.simple_date_format.errors || formCtr.separator.errors )"
                                     class="invalid-feedback offset-4 col-8 px-0">
                                    <div *ngIf="formCtr.simple_date_format.errors || formCtr.separator.errors">The date format is required</div>
                                    <div *ngIf="formCtr.simple_date_format.errors?.server_error">
                                        {{ formCtr.simple_date_format.errors.server_error }}</div>
                                    <div *ngIf="formCtr.separator.errors?.server_error">
                                        {{ formCtr.separator.errors.server_error }}</div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="team" class="col-4 col-form-label">Team:</label>
                                <select id="team" class="form-control col-sm-8" formControlName="team"
                                        [ngClass]="{ 'is-invalid': submitted && formCtr.team.errors }">
                                    <option disabled value="">- select team -</option>
                                    <option value="">(No Team)</option>
                                    <option *ngFor="let team of teams" value="{{ team.id }}">
                                        {{ team?.name }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && formCtr.team.errors" class="invalid-feedback offset-4">
                                    <div *ngIf="formCtr.team.errors.required">The team is required</div>
                                    <div *ngIf="formCtr.team.errors.server_error">
                                        {{ formCtr.team.errors.server_error }}</div>
                                </div>
                            </div>
                            <div *ngIf="submitted && registerForm.errors">
                                <div *ngIf="registerForm.errors[ 'server_error' ]">
                                    <div class="alert alert-danger" role="alert">
                                        {{ registerForm.errors['server_error'] }}
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group mt-4">
                                <button type="submit" class="btn btn-primary btn-block">
                                    Register
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <strong>AbaQus Field</strong> - Copyright 2019
        </div>
    </div>
</div>
<abq-message-modal [protected]="'static'"></abq-message-modal>
<abq-spinner-modal></abq-spinner-modal>
