import { Component, OnInit, ViewChild } from '@angular/core';
import { GetCountriesResponse } from '@shared/services/http/user-catalog/user-catalog-types';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordCheckService } from './reset-password-check.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageModalMode, MessageModalOptions } from '../../abq-common/message-modal/message-modal-types';
import { MessageModalComponent } from '../../abq-common/message-modal/message-modal.component';
import { ResetPasswordResponse } from '../reset-password/reset-password-types';

@Component({
    selector: 'abq-reset-password-check',
    templateUrl: './reset-password-check.component.html',
    styleUrls: ['./reset-password-check.component.scss']
})
export class ResetPasswordCheckComponent implements OnInit {

    public busy = true;
    public responseText = '';
    public showPasswordForm = false;
    public submitted = false;
    public resetPasswordForm: UntypedFormGroup;

    @ViewChild(MessageModalComponent) modalComponent: MessageModalComponent;

    constructor(protected formBuilder: UntypedFormBuilder,
                private router: Router,
                private resetPasswordCheckService: ResetPasswordCheckService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.resetPasswordForm = this.formBuilder.group({
            new_password: ['', [Validators.required]],
            password2: ['', [Validators.required]]
        }, {validator: this.matchingPasswords('new_password', 'password2')});
        this.checkResetPassword();
    }

    private matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
        return (group: UntypedFormGroup) => {
            let res = null;
            const passwordInput = group.controls[passwordKey];
            const passwordConfirmationInput = group.controls[passwordConfirmationKey];
            if (passwordInput.value !== passwordConfirmationInput.value) {
                res = {invalid: 'Passwords don\'t match '};
            }
            return res;
        };
    }

    // Convenience getter for easy access to form fields.
    get formCtr() {
        return this.resetPasswordForm.controls;
    }

    public checkResetPassword() {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        this.resetPasswordCheckService
            .checkChangePassword$(queryParams.ui_db64, queryParams.token)
            .subscribe({
                next: this.manageCheckResetPasswordResponse.bind(this),
                error: this.manageCheckResetPasswordErrors.bind(this)
            });
    }

    private manageCheckResetPasswordResponse(response: GetCountriesResponse) {
        this.busy = false;
        if (response.success) {
            this.showPasswordForm = true;
        } else {
            this.responseText = ('Error in resetting process: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageCheckResetPasswordErrors(response: any) {
        this.busy = false;
        let message = 'Unexpected error in in resetting process';
        if (response.error && response.error.errors) {
            message += (': ' + response.error.errors);
            if (response.error.form_errors) {
                for (const key of Object.keys(response.error.form_errors)) {
                    message += ('; ' + key + ': ' + response.error.form_errors[key]);
                }
            }
        }
        this.responseText = message;
    }

    resetPassword() {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        this.submitted = true;
        if (this.resetPasswordForm.invalid === false) {
            this.busy = true;
            this.resetPasswordCheckService
                .changePassword$(queryParams.ui_db64, queryParams.token, this.formCtr.new_password.value)
                .subscribe({
                    next: this.manageResetPasswordResponse.bind(this),
                    error: this.manageResetPasswordErrors.bind(this)
                });
        }
    }

    private manageResetPasswordResponse(response: ResetPasswordResponse) {
        this.busy = false;
        if (response.success) {
            const dialogOptions: MessageModalOptions = {
                title: 'Request processed',
                text: 'The password has been successfully reset. Now you can try lo sign in.',
                mode: MessageModalMode.CONTINUE,
                hideTimesButton: true,
                continueAction: () => {
                    // noinspection JSIgnoredPromiseFromCall
                    this.router.navigate(['/login']);
                }
            };
            this.modalComponent.show(dialogOptions);
        } else {
            this.manageErrorResponse(response);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageResetPasswordErrors(response: any) {
        this.busy = false;
        this.manageErrorResponse(response.error);
    }

    private manageErrorResponse(resetResponse: any) {
        if (resetResponse.form_errors) {
            for (const field in resetResponse.form_errors) {
                if (resetResponse.form_errors.hasOwnProperty(field)) {
                    const control = this.resetPasswordForm.get(field);
                    if (control) {
                        control.markAsTouched();
                        control.setErrors({server_error: resetResponse.form_errors[field]});
                    } else {
                        this.resetPasswordForm.markAsTouched();
                        this.resetPasswordForm.setErrors({server_error: resetResponse.form_errors[field]});
                    }
                }
            }
        } else if (resetResponse.errors) {
            this.resetPasswordForm.markAsTouched();
            this.resetPasswordForm.setErrors({server_error: resetResponse.errors});
        }
    }
}
