import { GeneralDTO, GeneralResponse } from '@shared/services/http/general-response/general-response';

export class GetTrialStudyReviewersResponse extends GeneralResponse {

    trialStudyReviewers: string[];

    constructor(getTrialStudyReviewersDTO: GetTrialStudyReviewersDTO) {
        super(getTrialStudyReviewersDTO);
        this.trialStudyReviewers = getTrialStudyReviewersDTO.trial_study_reviewers;
    }
}

export interface GetTrialStudyReviewersDTO extends GeneralDTO {
    trial_study_reviewers: string[];
}

