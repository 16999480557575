import { RegisterComponentChildViews } from './register-component-child-views';
import { GetDateFormatsResponse } from '@shared/services/http/user-catalog/user-catalog-types';
import { UserCatalogService } from '@shared/services/http/user-catalog/user-catalog.service';

export class DateFormatListGetter {

    constructor(private userCatalogService: UserCatalogService,
                private registerComponentChildViews: RegisterComponentChildViews) {
    }

    public getDateFormats() {
        this.userCatalogService
            .getDateFormats$()
            .subscribe({
                next: this.manageGetDateFormatsResponse.bind(this),
                error: this.manageGetDateFormatsErrors.bind(this)
            });
    }

    private manageGetDateFormatsResponse(response: GetDateFormatsResponse) {
        if (response.success) {
            this.registerComponentChildViews.updateDateFormats(response.simple_date_format, response.separator);
        } else {
            console.log('Error received getting date formats: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageGetDateFormatsErrors(error: any) {
        console.log('Unexpected error received getting date formats: ' + JSON.stringify(error));
    }
}
