import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthErrorInterceptorService implements HttpInterceptor {

    constructor(private router: Router) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const interceptionOperator = tap<HttpEvent<any>>(
            () => {
            },
            this.catchError
        );
        const handledRequest = next.handle(req);
        return handledRequest.pipe(interceptionOperator);
    }

    private catchError = err => {
        if (err instanceof HttpErrorResponse) {
            this.catchHttpError(err);
        }
    }

    private catchHttpError(err: HttpErrorResponse) {
        if (err.status === 403) {
            this.catchForbidden();
        }
    }

    private catchForbidden() {
        console.log('Not authorized');
        this.navigateToLogin();
    }

    private navigateToLogin() {
        this.router.navigateByUrl('/login');
    }
}
