import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IndicationResponse, IndicationsDTO } from '@shared/services/http/indication/indication-response';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IndicationService {

    static readonly urlList = '/abaqus/rest_api/webapp/info/indications/';

    constructor(private http: HttpClient) {
    }

    public obtainList$(): Observable<IndicationResponse> {
        return this.http.get<IndicationsDTO>(IndicationService.urlList)
            .pipe(map((indicationsDTO: IndicationsDTO) => new IndicationResponse(indicationsDTO)));
    }
}
