<div #multipleSelect class="btn-group">

    <div class="position-relative custom-select-btn">
        <button class="btn mx-1 p-2 text-left dropdown-toggle" type="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" [disabled]="disabled" [ngClass]="{'disabled': disabled}">
            - Select {{ name ? name : ''}} -
        </button>

        <div class="dropdown-menu">
            <div *ngIf="searchBox === true" class="input-group px-2">
                <span class="input-group-prepend">
                    <i class="fa fa-search position-absolute"></i>
                    <!--suppress HtmlFormInputWithoutLabel -->
                    <input #myInput id="search-input" class="form-control py-2 border ml-2" placeholder="Search..."
                           (input)="searchString( myInput.value )">
               </span>
            </div>
            <ng-scrollbar class="scrollable-menu position-relative"
                          [track]="'vertical'" [pointerEventsMethod]="'scrollbar'"
                          [autoHeightDisabled]="false" [autoWidthDisabled]="false"
                          (click)="$event.stopPropagation()">
                <ng-container *ngFor="let value of visibleElements">
                    <button class="dropdown-item py-1" (click)="elementClicked( value )">
                        <i class="fa fa-check text-primary mr-2" *ngIf="isSelected(value)"></i>
                        {{ value }}
                    </button>
                </ng-container>
            </ng-scrollbar>
            <div>
                <button class="btn btn-primary btn-sm mx-2 py-0 float-right" type="button" (click)="applyChanges()">
                    <i class="fa fa-sync"></i>
                    Apply
                </button>
            </div>
        </div>
    </div>
</div>
