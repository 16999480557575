<div class="container animated zoomIn fast">
    <div class="row">
        <div class="col">
            <div id="logo">
                <a routerLink="/">
                    <img src="assets/img/logo.svg" draggable="false" alt="Logo">
                </a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="col text-center">
                        <h4>AbaQus Field · Sign in</h4>
                    </div>
                    <div class="col">
                        <form [formGroup]="loginForm" (ngSubmit)="login()">
                            <a class="btn btn-primary btn-block text-white" role="button" aria-disabled="false" routerLink="/auth/sso">
                                BASF users access
                            </a>
                            <hr class="bg-primary mt-4">
                            <div class="form-group">
                                <label>Only for external users:</label>
                            </div>
                            <div class="row form-group">
                                <div class="col-4">
                                    <label class="light" for="username">User name:</label>
                                </div>
                                <div class="col-8">
                                    <input type="text" formControlName="username" id="username" autocomplete="off"
                                           class="form-control" placeholder="insert username"
                                           [ngClass]="{ 'is-invalid': submitted && formCtr.username.errors }"/>
                                    <div *ngIf="submitted && formCtr.username.errors" class="invalid-feedback">
                                        <div *ngIf="formCtr.username.errors.required">The username is required</div>
                                        <div *ngIf="formCtr.username.errors.server_error">
                                            {{ formCtr.username.errors.server_error }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-4">
                                    <label class="light" for="password">Password:</label>
                                </div>
                                <div class="col-8">
                                    <input type="password" formControlName="password" id="password"
                                           class="form-control" placeholder="******"
                                           [ngClass]="{ 'is-invalid': submitted && formCtr.password.errors }"/>
                                    <div *ngIf="submitted && formCtr.password.errors" class="invalid-feedback">
                                        <div *ngIf="formCtr.password.errors.required">The password is required</div>
                                        <div *ngIf="formCtr.password.errors.server_error">
                                            {{ formCtr.password.errors.server_error }}</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="submitted && loginForm.errors">
                                <div *ngIf="loginForm.errors[ 'server_error' ]">
                                    <div class="alert alert-danger" role="alert">
                                        {{ loginForm.errors[ 'server_error' ] }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <a [routerLink]="['/reset-password']">Reset password</a>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block">
                                Start session
                            </button>
                            <div class="form-group mt-3">
                                <strong>New to AbaQus Field?</strong> If you are a BASF user, you can register through the BASF access, otherwise, please
                                <a href="mailto:abaqus-field@tecnalia.com" target="_blank">
                                    Send an email to abaqus-field@tecnalia.com</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <strong>AbaQus Field</strong> - Copyright 2019
        </div>
    </div>
</div>
