import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RegisterResponse } from './register-types';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {

    private urlRegister = '/abaqus/rest_api/webapp/auth/register_user_ask/';

    constructor(private http: HttpClient) {
    }

    public register$(username: string, emailAddress: string, password: string, organization: number, country: string,
                     format: string, separator: string, fourDigitYear: boolean, team: number) {
        let params = new HttpParams();
        params = params.append('username', username);
        params = params.append('email', emailAddress);
        params = params.append('password', password);
        params = params.append('organization_code', organization.toString());
        params = params.append('country', country);
        params = params.append('simple_date_format', format);
        params = params.append('separator', separator);
        params = params.append('four_digit_year', fourDigitYear ? 'True' : 'False');
        if (team) {
            params = params.append('team', team.toString());
        }
        return this.http.get<RegisterResponse>(this.urlRegister, {params});
    }
}

