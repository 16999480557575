import { TrialCatalogService } from '@shared/services/http/trial-catalog/trial-catalog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TrialResearchersObtainerUpdater } from './trial-researchers-obtainer-updater';
import { GetTrialResearchersResponse } from '@shared/services/http/trial-catalog/get-trial-researchers-response';

export class TrialResearchersObtainer {
    constructor(
        private trialService: TrialCatalogService,
        private trialResearchersObtainerUpdater: TrialResearchersObtainerUpdater) {
    }

    public obtainTrialResearchers() {
        this.trialService.getTrialResearchers$().subscribe({
            next: this.manageObtainTrialResearchersResponse.bind(this),
            error: this.manageObtainTrialResearchersErrors.bind(this)
        });
    }

    private manageObtainTrialResearchersResponse(response: GetTrialResearchersResponse) {
        if (response.success) {
            this.trialResearchersObtainerUpdater.updateGetTrialResearchersResponse(response.trialResearchers);
        } else {
            console.log('Error received obtaining trial researchers: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageObtainTrialResearchersErrors(error: HttpErrorResponse) {
        console.log('Unexpected error received obtaining trial researchers: ' + JSON.stringify(error));
    }
}
