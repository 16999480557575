import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivateUserResponse } from './register-check-types';

@Injectable({
    providedIn: 'root'
})
export class RegisterCheckService {

    private urlActivateUser = '/abaqus/rest_api/webapp/auth/register_user_activate/';

    constructor(private http: HttpClient) {
    }

    public activateUser$(uid: string, token: string) {
        let params = new HttpParams();
        if (uid) {
            params = params.append('uid_b64', uid);
        }
        if (token) {
            params = params.append('token', token);
        }
        return this.http.get<ActivateUserResponse>(this.urlActivateUser, {params});
    }
}
