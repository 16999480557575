import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ListSelection } from './list-selection';


export interface CheckDropdownElement {
    id: number;
    name?: string;
    hidden?: boolean;
}

@Component({
    selector: 'abq-check-dropdown',
    templateUrl: './check-dropdown.component.html',
    styleUrls: ['./check-dropdown.component.scss']
})
export class CheckDropdownComponent implements AfterViewInit {

    @Input() public icon: string;
    @Input() public name: string;
    @Input() public searchBox: boolean;
    @Input() public applyButton: boolean;
    @Input() public allElement: boolean;
    @Input() public stateless: boolean = false;
    @Input() public disallowNoSelection: boolean = false;
    @Input() public elements: CheckDropdownElement[];
    @Input() public elementNameProperty: string = 'name';
    @Input() public resetScrollBar$: Observable<any>;
    @Input() public disabled: boolean = false;
    @Input() public hideOnSelectElement: boolean = false;
    @Output() public applyChangesSelected: EventEmitter<void> = new EventEmitter<void>();
    @Output() public elementSelected: EventEmitter<ListSelection> = new EventEmitter<ListSelection>();
    @Output() public elementSelectedDiff: EventEmitter<number> = new EventEmitter<number>();
    private selection: ListSelection = null;
    public isAllSelected: boolean;
    public selected = {};
    @ViewChild('checkDropdown') checkDropdownRef;
    private checkDropdown$;
    private changesApplied: boolean = false;
    private lastAppliedSelection: number[] = [];
    private lastAppliedSelectionAll: boolean;
    public applyButtonDisabled: boolean = false;

    constructor() {
        if (!this.stateless) {
            this.selection = new ListSelection();
        }
    }

    ngAfterViewInit(): void {
        this.checkDropdown$ = $(this.checkDropdownRef.nativeElement);
        this.checkDropdown$.on('hide.bs.dropdown', () => {
            if (this.applyButton) {
                if (!this.changesApplied) {
                    // Clicked outside the options.
                    this.selection.clear();
                    this.selection.selectAll(this.lastAppliedSelectionAll);
                    this.selection.selectElements(this.lastAppliedSelection);
                    this.selected = {};
                    this.isAllSelected = this.lastAppliedSelectionAll;
                    for (const element of this.selection.getSelection()) {
                        this.selected[element] = true;
                    }
                }
                this.changesApplied = false;
            }
        });
        this.checkDropdown$.children('.dropdown-toggle').dropdown();
    }

    setInitialSelection(selection: ListSelection) {
        this.selected = {};
        this.selection = selection;
        this.isAllSelected = selection.isAllSelected();
        for (const element of this.selection.getSelection()) {
            this.selected[element] = true;
        }
        this.lastAppliedSelection = [].concat(this.selection.getSelection());
        this.lastAppliedSelectionAll = this.selection.isAllSelected();
    }

    elementClicked(value): void {
        const valueId = value.id ?? value;
        if (!this.stateless) {
            this.selection.toggleElement(valueId);
            this.isAllSelected = this.selection && this.selection.isAllSelected();
            this.selected[valueId] = !this.selected[valueId];
            this.checkApplyButtonState();
            this.elementSelected.emit(this.selection);
        } else {
            this.elementSelectedDiff.emit(valueId);
        }
        if (this.hideOnSelectElement) {
            this.checkDropdown$.dropdown('toggle');
        }
    }

    private checkApplyButtonState() {
        if (this.disallowNoSelection) {
            this.applyButtonDisabled =
                this.disallowNoSelection && (this.selection.getSelection().length === 0) && !this.selection.isAllSelected();
        }
    }

    allClicked(): void {
        if (!this.stateless) {
            this.selection.toggleAll();
            this.isAllSelected = this.selection && this.selection.isAllSelected();
            this.selected = [];
            this.checkApplyButtonState();
            this.elementSelected.emit(this.selection);
        } else {
            this.elementSelectedDiff.emit(undefined);
        }
    }

    getSelection(): ListSelection {
        return this.selection;
    }

    applyChanges(): void {
        this.changesApplied = true;
        this.lastAppliedSelection = [].concat(this.selection.getSelection());
        this.lastAppliedSelectionAll = this.selection.isAllSelected();
        this.applyChangesSelected.emit();
    }

    searchString(value: string) {
        for (const elem of this.elements) {
            // noinspection RedundantIfStatementJS
            if (value === '' || elem[this.elementNameProperty].toLowerCase().indexOf(value.toLowerCase()) >= 0) {
                elem.hidden = false;
            } else {
                elem.hidden = true;
            }
        }
    }

    getValue(value: any) {
        if (this.elementNameProperty && value[this.elementNameProperty]) {
            return value[this.elementNameProperty];
        }

        if (this.elementNameProperty && value[this.elementNameProperty] === '') {
            return '[ not defined ]';
        }

        if (value.id !== null) {
            return value.id;
        }

        return value;
    }
}
