import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CropTypeResponse, CropTypeServiceDTO } from './crop-type-response';

@Injectable({
    providedIn: 'root'
})
export class CropTypeService {
    static readonly urlList = '/abaqus/rest_api/webapp/info/crop_types/';

    constructor(private http: HttpClient) {
    }

    public obtainList$(): Observable<CropTypeResponse> {
        return this.http.get<CropTypeServiceDTO>(CropTypeService.urlList)
            .pipe(map((cropTypeServiceDTO: CropTypeServiceDTO) => new CropTypeResponse(cropTypeServiceDTO)));
    }
}

