<div class="container animated zoomIn fast">
    <div class="row">
        <div class="col">
            <div id="logo">
                <a routerLink="/">
                    <img src="assets/img/logo.svg" draggable="false" alt="Logo">
                </a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="col text-center">
                        <h4>AbaQus Field · Password reset</h4>
                        <p>Please confirm your email address</p>
                    </div>
                    <div class="col">
                        <form [formGroup]="resetForm" (ngSubmit)="resetPassword()">
                            <div class="form-group">
                                <label for="email">Email address:</label>
                                <input type="text" formControlName="email" id="email" autocomplete="off"
                                       class="form-control" placeholder="insert email address"
                                       [ngClass]="{ 'is-invalid': submitted && formCtr.email.errors }" />
                                <div *ngIf="submitted && formCtr.email.errors" class="invalid-feedback">
                                    <div *ngIf="formCtr.email.errors.required">The email address is required</div>
                                    <div *ngIf="formCtr.email.errors.server_error">
                                        {{ formCtr.email.errors.server_error }}</div>
                                </div>
                            </div>
                            <div *ngIf="submitted && resetForm.errors">
                                <div *ngIf="resetForm.errors['server_error']">
                                    <div class="alert alert-danger" role="alert">
                                        {{ resetForm.errors['server_error'] }}
                                    </div>
                                </div>
                            </div>
<!--                            <div class="form-group">-->
<!--                                <p>After submitting this form, if your user name is valid, a new password will be sent to your email account.</p>-->
<!--                                <p>Check your email and then try to log in again with that password.</p>-->
<!--                                <p>Otherwise, you can try to <a routerLink="/login">log in</a> again</p>-->
<!--                            </div>-->
                            <button type="submit" class="btn btn-primary btn-block">Reset password</button>
                            <div class="form-group mt-3">
                                Or you can return to <a routerLink="/login">Sign in</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <strong>AbaQus Field</strong> - Copyright 2019
        </div>
    </div>
</div>
<abq-message-modal [protected]="'static'"></abq-message-modal>
<abq-spinner-modal></abq-spinner-modal>

