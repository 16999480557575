import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageToastService } from './message-toast.service';

@Component({
    selector: 'abq-message-toast',
    templateUrl: './message-toast.component.html',
    styleUrls: ['./message-toast.component.scss']
})
export class MessageToastComponent implements AfterViewInit, OnDestroy {

    public toastMessage: string;
    @Input() public serviceMode = false;
    @ViewChild('toast') toastRef;
    private toast$;
    private subscription: Subscription;

    constructor(private messageToastService: MessageToastService) {
    }

    public ngAfterViewInit(): void {
        this.toast$ = $(this.toastRef.nativeElement);
        if (this.serviceMode) {
            this.subscribeToMessageToastService();
        }
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private subscribeToMessageToastService() {
        this.subscription = this.messageToastService.onToastRequest$().subscribe({
            next: (message: string) => {
                this.show(message);
            }
        });
    }

    public show(toastMessage: string) {
        this.toastMessage = toastMessage;
        this.toast$.toast('show');
    }
}
