import { PlotDTO } from '@shared/services/http/plot-dto';

export class Plot {
    id: number;
    plotNumber: number;
    treatNumber: number;
    assessmentsWithImages: number[];


    constructor(id: number, plotNumber: number, treatNumber: number, assessmentsWithImages: number[]) {
        this.id = id;
        this.plotNumber = plotNumber;
        this.treatNumber = treatNumber;
        this.assessmentsWithImages = assessmentsWithImages;
    }

    public static createFromDTO(plotDTO: PlotDTO): Plot {
        return new Plot(plotDTO.id, plotDTO.number, plotDTO.treat_number, plotDTO.assessments_with_images);
    }

    public hasAssessmentsWithImages(): boolean {
        return this.assessmentsWithImages.length > 0;
    }

    public hasImagesInAssessment(assessmentId: number): boolean {
        return this.assessmentsWithImages.includes(assessmentId);
    }
}
