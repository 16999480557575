export class OrganismCode {
    private readonly id: number;
    private readonly code: string;
    private readonly indication: number;
    private readonly scientificName: string;

    constructor(id: number, code: string, indication: number, scientificName: string) {
        this.id = id;
        this.code = code;
        this.indication = indication;
        this.scientificName = scientificName;
    }

    public getId(): number {
        return this.id;
    }

    public getCode(): string {
        return this.code;
    }

    public getScientificName(): string {
        return this.scientificName;
    }
}
