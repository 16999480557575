import { HttpErrorResponse } from '@angular/common/http';
import { TrialStudyReviewersObtainerUpdater } from './trial-study-reviewers-obtainer-updater';
import { TrialCatalogService } from '@shared/services/http/trial-catalog/trial-catalog.service';
import { GetTrialStudyReviewersResponse } from '@shared/services/http/trial-catalog/get-trial-study-reviewers-response';

export class TrialStudyReviewersObtainer {
    constructor(
        private trialService: TrialCatalogService,
        private trialStudyReviewersObtainerUpdater: TrialStudyReviewersObtainerUpdater) {
    }

    public obtainTrialStudyReviewers() {
        this.trialService.getTrialStudyReviewers$().subscribe({
            next: this.manageObtainTrialStudyReviewersResponse.bind(this),
            error: this.manageObtainTrialStudyReviewersErrors.bind(this)
        });
    }

    private manageObtainTrialStudyReviewersResponse(response: GetTrialStudyReviewersResponse) {
        if (response.success) {
            this.trialStudyReviewersObtainerUpdater
                .updateGetTrialStudyReviewersResponse(response.trialStudyReviewers);
        } else {
            console.log('Error received obtaining trial study reviewers: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageObtainTrialStudyReviewersErrors(error: HttpErrorResponse) {
        console.log('Unexpected error received obtaining trial study reviewers: ' + JSON.stringify(error));
    }
}
