import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CountryResponse, CountryDTO } from './country-response';

@Injectable({
    providedIn: 'root'
})
export class CountryService {

    static readonly URL = '/abaqus/rest_api/webapp/info/basf_countries/';

    constructor(private http: HttpClient) {
    }

    obtain$(): Observable<string[]> {
        return this.obtainCountryResponse$().pipe(
            map((response: CountryResponse) => {
                if ( response.success) {
                    return response.countries;
                } else {
                    throwError('Error received obtaining organism types: ' + response.errors)
                }
            }),
            catchError((error: HttpErrorResponse) => throwError(error.message))
        );
    }

    private obtainCountryResponse$(): Observable<CountryResponse> {
        return this.http.get<CountryDTO>(CountryService.URL)
            .pipe(map((countryDTO: CountryDTO) => new CountryResponse(countryDTO)));
    }
}
