import { GeneralDTO, GeneralResponse } from '@shared/services/http/general-response/general-response';

export class GetTrialProtocolsResponse extends GeneralResponse {

    trialProtocols: string[];

    constructor(getTrialProtocolsDTO: GetTrialProtocolsDTO) {
        super(getTrialProtocolsDTO);
        this.trialProtocols = getTrialProtocolsDTO.trial_protocols;
    }
}

export interface GetTrialProtocolsDTO extends GeneralDTO {
    trial_protocols: string[];
}
