<div #messageModal class="modal fade" tabindex="-1" role="dialog" aria-labelledby="message-modal-title"
     aria-hidden="true" [attr.data-backdrop]="protected">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="message-modal-title">{{ options?.title }}</h5>
                <ng-container *ngIf="options?.hideTimesButton !== true">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ng-container>
            </div>
            <div class="modal-body">
                <p id="message-modal-text" [innerHTML]="options?.text"></p>
                <p id="message-modal-detail-text" [innerHTML]="options?.detailText"></p>
            </div>
            <div class="modal-footer">
                <ng-container *ngIf="options?.mode === undefined ||
                                     options?.mode === messageModalMode.CLOSE">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                </ng-container>
                <ng-container *ngIf="options?.mode === messageModalMode.CONTINUE_CANCEL">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="doContinueAction()">Continue</button>
                </ng-container>
                <ng-container *ngIf="options?.mode === messageModalMode.CONTINUE">
                    <button type="button" class="btn btn-primary" (click)="doContinueAction()">Continue</button>
                </ng-container>
                <ng-container *ngIf="options?.mode === messageModalMode.YES_NO_CANCEL">
                    <button type="button" class="btn btn-secondary float-left" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="doNoAction()">No</button>
                    <button type="button" class="btn btn-primary" (click)="doYesAction()">Yes</button>
                </ng-container>
                <ng-container *ngIf="options?.mode === messageModalMode.YES_NO">
                    <button type="button" class="btn btn-primary" (click)="doNoAction()">No</button>
                    <button type="button" class="btn btn-primary" (click)="doYesAction()">Yes</button>
                </ng-container>
                <ng-container *ngIf="options?.mode === messageModalMode.ACCEPT">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Accept</button>
                </ng-container>
            </div>
        </div>
    </div>
</div>
