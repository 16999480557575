<div *ngIf="show" class="card-img-overlay p-0">
    <div class="d-flex justify-content-center align-items-center h-100 spinner-overlay"></div>
    <div id="absolute-overlay" class="position-absolute d-flex flex-column justify-content-center align-items-center h-100 w-100" style="top: 0;">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <a *ngIf="cancellable" class="btn btn-secondary btn-sm text-white mt-2" role="button"
            (click)="cancelSpinner()">
            {{ cancelButtonText }}
        </a>
    </div>
</div>
