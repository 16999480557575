import { GeneralDTO, GeneralResponse } from '@shared/services/http/general-response/general-response';

export class GetTrialGroupsResponse extends GeneralResponse {

    trialGroups: string[];

    constructor(getTrialGroupsDTO: GetTrialGroupsDTO) {
        super(getTrialGroupsDTO);
        this.trialGroups = getTrialGroupsDTO.trial_groups;
    }
}

export interface GetTrialGroupsDTO extends GeneralDTO {
    trial_groups: string[];
}
