import { AfterViewInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { config } from '../../logged/abq-common/config';
import { Subscription } from 'rxjs';
import { SpinnerModalService } from './spinner-modal.service';

@Component({
    selector: 'abq-spinner-modal',
    templateUrl: './spinner-modal.component.html',
    styleUrls: ['./spinner-modal.component.scss']
})
export class SpinnerModalComponent implements AfterViewInit, OnDestroy {

    @Input() public serviceMode = false;
    @ViewChild('spinnerModal') spinnerModalRef;
    private spinnerModal$;
    private subscription: Subscription;

    constructor(private spinnerModalService: SpinnerModalService) {
    }

    public ngAfterViewInit(): void {
        this.spinnerModal$ = $(this.spinnerModalRef.nativeElement);
        if (this.serviceMode) {
            this.subscribeToSpinnerModalService();
        }
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private subscribeToSpinnerModalService() {
        this.subscription = this.spinnerModalService.onSpinnerRequest$().subscribe({
            next: (visible: boolean) => {
                if (visible) {
                    this.show();
                } else {
                    this.hide();
                }
            }
        });
    }

    public show() {
        this.spinnerModal$.modal('show');
    }

    public hide() {
        setTimeout(() => {
            this.spinnerModal$.modal('hide');
        }, config.abqCloseModalAnimationTime);
    }

}
