import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RegisterResponse } from './register-types';
import { OrganizationListGetter } from './organization-list-getter';
import { RegisterService } from './register.service';
import { RegisterComponentChildViews } from './register-component-child-views';
import { CountryListGetter } from './country-list-getter';
import { DateFormatListGetter } from './date-format-list-getter';
import { MessageModalMode, MessageModalOptions } from '../../abq-common/message-modal/message-modal-types';
import { MessageModalComponent } from '../../abq-common/message-modal/message-modal.component';
import { SpinnerModalComponent } from '../../abq-common/spinner-modal/spinner-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ArraySort } from '../../abq-common/array-sort';
import { UserCatalogService } from '@shared/services/http/user-catalog/user-catalog.service';
import { Country, Format, Organization, Separator } from '@shared/services/http/user-catalog/user-catalog-types';
import { TeamDTO } from '@shared/services/http/team-dto';
import { TeamsService } from '@shared/services/team/teams.service';

@Component({
    selector: 'abq-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, RegisterComponentChildViews {

    public submitted = false;
    public registerForm: UntypedFormGroup;
    public organizations: Organization[];
    public countries: Country[];
    public formats: Format[];
    public separators: Separator[];
    public teams: TeamDTO[];

    @ViewChild(MessageModalComponent) modalComponent: MessageModalComponent;
    @ViewChild(SpinnerModalComponent) spinnerModalComponent: SpinnerModalComponent;

    private organizationListGetter: OrganizationListGetter;
    private countryListGetter: CountryListGetter;
    private dateFormatListGetter: DateFormatListGetter;
    public username = 'noname';
    public email = 'noname@noname.com';

    constructor(
        protected formBuilder: UntypedFormBuilder,
        private router: Router,
        private registerService: RegisterService,
        private userCatalogService: UserCatalogService,
        private activatedRoute: ActivatedRoute,
        private teamsService: TeamsService
    ) {
        this.organizationListGetter = new OrganizationListGetter(this.userCatalogService, this);
        this.countryListGetter = new CountryListGetter(this.userCatalogService, this);
        this.dateFormatListGetter = new DateFormatListGetter(this.userCatalogService, this);
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            this.username = params.username;
            this.email = params.email;
        });
        this.registerForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            organization_code: ['', [Validators.required]],
            country: ['', [Validators.required]],
            simple_date_format: ['', [Validators.required]],
            separator: ['', [Validators.required]],
            four_digit_year: [''],
            team: ['']
        });

        this.organizationListGetter.getOrganizations();
        this.countryListGetter.getCountries();
        this.dateFormatListGetter.getDateFormats();
        this.teamsService.getTeams$().subscribe((teams: TeamDTO[]) => {
            this.teams = teams.sort(new ArraySort().dynamicSort('name'));
        });
    }

    // Convenience getter for easy access to form fields.
    get formCtr() {
        return this.registerForm.controls;
    }

    updateOrganizations(organizations: Organization[]): void {
        this.organizations = organizations;
    }

    updateCountries(countries: Country[]): void {
        this.countries = countries;
    }

    updateDateFormats(formats: Format[], separators: Separator[]): void {
        this.formats = formats;
        this.separators = separators;
    }

    public register() {
        this.submitted = true;
        console.log('Registering...');
        if (this.registerForm.invalid === false) {
            this.spinnerModalComponent.show();
            // this.userStateService.setUserAnonymous( true );
            this.registerService.register$(this.username, this.email, this.formCtr.password.value,
                this.formCtr.organization_code.value, this.formCtr.country.value,
                this.formCtr.simple_date_format.value, this.formCtr.separator.value,
                this.formCtr.four_digit_year.value, this.formCtr.team.value)
                .subscribe({
                    next: this.manageRegisterResponse.bind(this, this.email),
                    error: this.manageRegisterError.bind(this)
                });
        }
    }

    private manageRegisterResponse(email: string, registerResponse: RegisterResponse) {
        if (registerResponse.success) {
            this.spinnerModalComponent.hide();
            const dialogOptions: MessageModalOptions = {
                title: 'Request processed',
                text: 'A request has been sent to the administrator. When he accepts it, an email with a ' +
                    'confirmation link will be sent to your ' + email + ' address, and you will be able to sign ' +
                    'in with it.',
                mode: MessageModalMode.CONTINUE,
                hideTimesButton: true,
                continueAction: () => {
                    // noinspection JSIgnoredPromiseFromCall
                    this.router.navigate(['/login']);
                }
            };
            this.modalComponent.show(dialogOptions);
        } else {
            this.manageErrorResponse(registerResponse);
        }
    }

    private manageRegisterError(error: any) {
        this.manageErrorResponse(error.error);
    }

    // noinspection JSMethodCanBeStatic
    private spaceValidator(control: UntypedFormControl) {
        if (control.value.indexOf(' ') >= 0) {
            return {
                trimError: {value: 'control has spaces'}
            };
        }
        return null;
    }

    private manageErrorResponse(registerResponse: any) {
        this.spinnerModalComponent.hide();
        if (registerResponse.form_errors) {
            for (const field in registerResponse.form_errors) {
                if (registerResponse.form_errors.hasOwnProperty(field)) {
                    const control = this.registerForm.get(field);
                    if (control) {
                        control.markAsTouched();
                        control.setErrors({server_error: registerResponse.form_errors[field]});
                    } else {
                        this.registerForm.markAsTouched();
                        this.registerForm.setErrors({server_error: registerResponse.form_errors[field]});
                    }
                }
            }
        } else if (registerResponse.errors) {
            this.registerForm.markAsTouched();
            this.registerForm.setErrors({server_error: registerResponse.errors});
        }
    }
}
