<div class="container animated zoomIn fast">
    <div class="row">
        <div class="col">
            <div id="logo">
                <a routerLink="/">
                    <img src="assets/img/logo.svg" draggable="false" alt="Logo">
                </a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="col text-center">
                        <h4>AbaQus Field · Session ended</h4>
                    </div>
                    <div class="col">
                        <div class="form-group mt-3">
                            Click
                            <a [routerLink]="['/']">here</a>
                            to login again.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <strong>AbaQus Field</strong> - Copyright 2019
        </div>
    </div>
</div>
