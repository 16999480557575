import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './modules/login/register/register.component';
import { RegisterCheckComponent } from './modules/login/register-check/register-check.component';
import { LoggedOutComponent } from './modules/login/logged-out/logged-out.component';
import { LoginComponent } from './modules/login/login/login.component';
import { ResetPasswordComponent } from './modules/login/reset-password/reset-password.component';
import { ResetPasswordCheckComponent } from './modules/login/reset-password-check/reset-password-check.component';
import { LoggedShownComponent } from './modules/login/logged-shown/logged-shown.component';
import { MaintenanceComponent } from './modules/login/maintenance/maintenance.component';


const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'loggedout',
        component: LoggedOutComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'register_check',
        component: RegisterCheckComponent
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent
    },
    {
        path: 'password_change_check',
        component: ResetPasswordCheckComponent
    },
    {
        path: 'logged-shown',
        component: LoggedShownComponent
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    },
    {
        path: '',
        loadChildren: () => import('./modules/logged/logged.module')
            .then(m => m.LoggedModule),
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
