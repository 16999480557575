import { Component, OnInit } from '@angular/core';
import { LoginResponse } from './login-response';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'abq-generic-login',
    template: '<p>generic-login works!</p>',
    styles: []
})
export class GenericLoginComponent implements OnInit {
    submitted = false;
    loginForm: UntypedFormGroup;
    public returnUrl: string;

    constructor(
        protected loginService: LoginService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected formBuilder: UntypedFormBuilder
    ) {
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });

        this.returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl || '/';
    }

    // Convenience getter for easy access to form fields.
    get formCtr() {
        return this.loginForm.controls;
    }

    public login() {
        this.submitted = true;
        if (this.loginForm.invalid === false) {
            this.loginService.login$(this.formCtr.username.value, this.formCtr.password.value)
                .subscribe({
                    next: this.manageLoginResponse.bind(this),
                    error: this.manageRecoverError.bind(this)
                });
        }
    }

    private manageLoginResponse(loginResponse: LoginResponse) {
        if (loginResponse.success) {
            // noinspection JSIgnoredPromiseFromCall
            this.router.navigateByUrl(this.returnUrl);
        } else {
            this.manageResponse(loginResponse);
        }
    }

    private manageRecoverError(error: any) {
        this.manageResponse(error.error);
    }

    private manageResponse(recoverResponse: any) {
        if (recoverResponse.form_errors) {
            for (const field in recoverResponse.form_errors) {
                if (recoverResponse.form_errors.hasOwnProperty(field)) {
                    const control = this.loginForm.get(field);
                    if (control) {
                        control.markAsTouched();
                        control.setErrors({server_error: recoverResponse.form_errors[field]});
                    } else {
                        this.loginForm.markAsTouched();
                        this.loginForm.setErrors({server_error: recoverResponse.form_errors[field]});
                    }
                }
            }
        } else if (recoverResponse.errors) {
            this.loginForm.markAsTouched();
            this.loginForm.setErrors({server_error: recoverResponse.errors});
        }
    }

}
