import { Component, OnInit } from '@angular/core';
import { GenericLoginComponent } from '../generic-login/generic-login.component';
import { LoginService } from '../generic-login/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
    selector: 'abq-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends GenericLoginComponent implements OnInit {

    constructor(
        protected loginService: LoginService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected formBuilder: UntypedFormBuilder
    ) {
        super(loginService, activatedRoute, router, formBuilder);
        loginService.setUrlLogin('/abaqus/rest_api/webapp/auth/login/');
    }

    ngOnInit() {
        super.ngOnInit();
    }

}
