<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Team)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [stateless]="true" [searchBox]="true"
                            [icon]="'fa-users'" [name]="'Team'" [elements]="teams.all"
                            (elementSelectedDiff)="onElementSelected($event, teams)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-users'" [deletable]="true"
                        [elements]="teams.selected"
                        (removeClicked)="onRemoveFilterValue($event, teams)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Use)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [stateless]="true"
                            [icon]="'fa-toolbox'" [name]="'Use'" [elements]="uses.all"
                            (elementSelectedDiff)="onElementSelected($event, uses)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-toolbox'" [deletable]="true"
                        [elements]="uses.selected"
                        (removeClicked)="onRemoveFilterValue($event, uses)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Crop)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [searchBox]="true" [stateless]="true"
                            [icon]="'fa-leaf'" [name]="'Crop'" [elements]="crops.all"
                            (elementSelectedDiff)="onElementSelected($event, crops)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-leaf'" [deletable]="true"
                        [elements]="crops.selected"
                        (removeClicked)="onRemoveFilterValue($event, crops)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Organism)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [searchBox]="true" [stateless]="true"
                            [icon]="'fa-bug'" [name]="'Organism'" [elements]="organisms.all"
                            (elementSelectedDiff)="onElementSelected($event, organisms)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-bug'" [deletable]="true"
                        [elements]="organisms.selected"
                        (removeClicked)="onRemoveFilterValue($event, organisms)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Cooperator)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [searchBox]="true" [stateless]="true"
                            [icon]="'fa-handshake'" [name]="'Cooperator'" [elements]="cooperators.all"
                            (elementSelectedDiff)="onElementSelected($event, cooperators)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-handshake'" [deletable]="true"
                        [elements]="cooperators.selected"
                        (removeClicked)="onRemoveFilterValue($event, cooperators)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.ProtocolOriginator)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [searchBox]="true" [stateless]="true"
                            [icon]="'fa-share-square'" [name]="'Protocol Originator'"
                            [elements]="protocolOriginators.all"
                            (elementSelectedDiff)="onElementSelected($event, protocolOriginators)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-share-square'" [deletable]="true"
                        [elements]="protocolOriginators.selected"
                        (removeClicked)="onRemoveFilterValue($event, protocolOriginators)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.TrialResearcher)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [searchBox]="true" [stateless]="true"
                            [icon]="'fa-microscope'" [name]="'Trial Researcher'" [elements]="researchers.all"
                            (elementSelectedDiff)="onElementSelected($event, researchers)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-microscope'" [deletable]="true"
                        [elements]="researchers.selected"
                        (removeClicked)="onRemoveFilterValue($event, researchers)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.StudyReviewer)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [searchBox]="true" [stateless]="true"
                            [icon]="'fa-search'" [name]="'Study Reviewer'" [elements]="studyReviewers.all"
                            (elementSelectedDiff)="onElementSelected($event, studyReviewers)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-search'" [deletable]="true"
                        [elements]="studyReviewers.selected"
                        (removeClicked)="onRemoveFilterValue($event, studyReviewers)">
        </abq-badge-list>
    </div>
</div>

<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Type)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [stateless]="true"
                            [icon]="'fa-stream'" [name]="'Type'" [elements]="trialTypes.all"
                            (elementSelectedDiff)="onElementSelected($event, trialTypes)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-stream'" [deletable]="true"
                        [elements]="trialTypes.selected"
                        (removeClicked)="onRemoveFilterValue($event, trialTypes)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Indication)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [stateless]="true"
                            [icon]="'fa-prescription-bottle'" [name]="'Indication'"
                            [elements]="indications.all"
                            (elementSelectedDiff)="onElementSelected($event, indications)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-prescription-bottle'" [deletable]="true"
                        [elements]="indications.selected"
                        (removeClicked)="onRemoveFilterValue($event, indications)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Year)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [stateless]="true"
                            [icon]="'fa-calendar-alt'" [name]="'Year'" [searchBox]="true"
                            [elements]="years.all"
                            (elementSelectedDiff)="onElementSelected($event, years)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-calendar-alt'" [deletable]="true"
                        [elements]="years.selected"
                        (removeClicked)="onRemoveFilterValue($event, years)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.OriginCountry)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [stateless]="true"
                            [icon]="'fa-clone'" [name]="'Origin Country'"
                            [elements]="originCountries.all"
                            (elementSelectedDiff)="onElementSelected($event, originCountries)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-clone'" [deletable]="true"
                        [elements]="originCountries.selected"
                        (removeClicked)="onRemoveFilterValue($event, originCountries)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Protocol)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [stateless]="true" [searchBox]="true"
                            [icon]="'fa-book'" [name]="'Protocol'" [elements]="protocols.all"
                            (elementSelectedDiff)="onElementSelected($event, protocols)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-book'" [deletable]="true"
                        [elements]="protocols.selected"
                        (removeClicked)="onRemoveFilterValue($event, protocols)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Version)">
    <div class="col-4 pt-0 pb-2">
        <label for="version" class="d-none"></label>
        <input #version type="text" id="version" autocomplete="off" maxlength="1"
               style="height: 1.8em; margin-left: 0.5em; padding-left: 8em;"
               class="form-control mr-2" placeholder="enter value"
               (change)="onElementInputted(version.value, versions); version.value = '';"/>
        <div class="position-absolute text-primary" style="top: 0.2em; left: 4em;">
            <i class="fa fa-clipboard-list"></i>
            Version
        </div>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-clipboard-list'" [deletable]="true"
                        [elements]="versions.selected"
                        (removeClicked)="onRemoveFilterValue($event, versions)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Revision)">
    <div class="col-4 pt-0 pb-2">
        <label for="revision" class="d-none"></label>
        <input #revision type="text" id="revision" autocomplete="off" maxlength="4"
               style="height: 1.8em; margin-left: 0.5em; padding-left: 8em;"
               class="form-control mr-2" placeholder="enter value"
               (change)="onElementInputted(revision.value, revisions); revision.value = '';"/>
        <div class="position-absolute text-primary" style="top: 0.2em; left: 4em;">
            <i class="fa fa-tag"></i>
            Revision
        </div>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-tag'" [deletable]="true"
                        [elements]="revisions.selected"
                        (removeClicked)="onRemoveFilterValue($event, revisions)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Country)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [stateless]="true"
                            [icon]="'fa-globe-africa'" [name]="'Country'"
                            [elements]="countries.all" [searchBox]="true"
                            (elementSelectedDiff)="onElementSelected($event, countries)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-globe-africa'" [deletable]="true"
                        [elements]="countries.selected"
                        (removeClicked)="onRemoveFilterValue($event, countries)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Site)">
    <div class="col-4">
        <abq-check-dropdown class="mb-2 ml-1 btn-block" [stateless]="true" [searchBox]="true"
                            [icon]="'fa-map-marker-alt'" [name]="'Site'" [elements]="sites.all"
                            (elementSelectedDiff)="onElementSelected($event, sites)">
        </abq-check-dropdown>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-map-marker-alt'" [deletable]="true"
                        [elements]="sites.selected"
                        (removeClicked)="onRemoveFilterValue($event, sites)">
        </abq-badge-list>
    </div>
</div>
<div class="row" [hidden]="!shownFilters.includes(EXPOSE_FILTER_FIELD_ID.Number)">
    <div class="col-4 pt-0 pb-2">
        <label for="number" class="d-none"></label>
        <input #number type="text" id="number" autocomplete="off" maxlength="3"
               style="height: 1.8em; margin-left: 0.5em; padding-left: 8em;"
               class="form-control mr-2" placeholder="enter value"
               (change)="onElementInputted(number.value, numbers); number.value = '';"/>
        <div class="position-absolute text-primary" style="top: 0.2em; left: 4em;">
            <i class="fa fa-hashtag"></i>
            Number
        </div>
    </div>
    <div class="col">
        <abq-badge-list class="align-middle"
                        [icon]="'fa-hashtag'" [deletable]="true"
                        [elements]="numbers.selected"
                        (removeClicked)="onRemoveFilterValue($event, numbers)">
        </abq-badge-list>
    </div>
</div>
<div class="form">
    <div class="row form-group">
        <div class="col d-flex justify-content-end">
            <button class="btn btn-sm btn-primary my-auto mr-2"
                    (click)="onClear( )">
                <i class="fa fa-eraser"></i>
                Clear
            </button>
            <button class="btn btn-sm btn-primary my-auto"
                    (click)="onApplyFilter( )">
                <i class="fa fa-sync"></i>
                Apply
            </button>
        </div>
    </div>
</div>
