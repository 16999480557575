import { GeneralDTO, GeneralResponse } from '@shared/services/http/general-response/general-response';

export class GetTrialResearchersResponse extends GeneralResponse {

    trialResearchers: string[];

    constructor(getTrialResearchersDTO: GetTrialResearchersDTO) {
        super(getTrialResearchersDTO);
        this.trialResearchers = getTrialResearchersDTO.trial_researchers;
    }
}

export interface GetTrialResearchersDTO extends GeneralDTO {
    trial_researchers: string[];
}
