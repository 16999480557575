import { Component, OnInit } from '@angular/core';
import { GetCountriesResponse } from '@shared/services/http/user-catalog/user-catalog-types';
import { RegisterCheckService } from './register-check.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'abq-register-check',
    templateUrl: './register-check.component.html',
    styleUrls: ['./register-check.component.scss']
})
export class RegisterCheckComponent implements OnInit {

    public busy = true;
    public responseText = '';

    constructor(private registerCheckService: RegisterCheckService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.activateUser();
    }

    public activateUser() {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        this.registerCheckService
            .activateUser$(queryParams.ui_db64, queryParams.token)
            .subscribe({
                next: this.manageActivateUserResponse.bind(this),
                error: this.manageActivateUserErrors.bind(this)
            });
    }

    private manageActivateUserResponse(response: GetCountriesResponse) {
        this.busy = false;
        if (response.success) {
            this.responseText = 'User successfully activated.';
        } else {
            this.responseText = ('Error in activation process: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageActivateUserErrors(response: any) {
        this.busy = false;
        let message = 'Unexpected error in activation process';
        if (response.error && response.error.errors) {
            message += (': ' + response.error.errors);
            if (response.error.form_errors) {
                for (const key of Object.keys(response.error.form_errors)) {
                    message += ('; ' + key + ': ' + response.error.form_errors[key]);
                }
            }
        }
        this.responseText = message;
    }
}
