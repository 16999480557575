<div class="container animated zoomIn fast">
    <div class="row">
        <div class="col">
            <div id="logo">
                <a routerLink="/">
                    <img src="assets/img/logo.svg" draggable="false" alt="Logo">
                </a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="col text-center mb-4">
                        <h5>AbaQus Field · Password reset check</h5>
                    </div>
                    <div *ngIf="!showPasswordForm" class="col">
                        <p>{{ responseText }}</p>
                    </div>
                    <div *ngIf="showPasswordForm" class="col">
                        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
                            <div class="row form-group">
                                <label for="password" class="col-4 col-form-label">New password:</label>
                                <input type="password" formControlName="new_password" id="password" autocomplete="off"
                                       class="form-control col-sm-8" placeholder="insert password"
                                       [ngClass]="{ 'is-invalid': submitted && formCtr.new_password.errors }"/>
                                <div *ngIf="submitted && formCtr.new_password.errors" class="invalid-feedback offset-4">
                                    <div *ngIf="formCtr.new_password.errors.required">The new password is required
                                    </div>
                                    <div *ngIf="formCtr.new_password.errors.server_error">
                                        {{ formCtr.new_password.errors.server_error }}</div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="password2" class="col-4 col-form-label">Repeat password:</label>
                                <input type="password" formControlName="password2" id="password2" autocomplete="off"
                                       class="form-control col-sm-8" placeholder="insert password"
                                       [ngClass]="{ 'is-invalid': submitted && formCtr.password2.errors }"/>
                                <div *ngIf="submitted && formCtr.new_password.errors" class="invalid-feedback offset-4">
                                    <div *ngIf="formCtr.new_password.errors.required">The repeated new password is required
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="submitted && resetPasswordForm.errors">
                                <div *ngIf="resetPasswordForm.errors.invalid">
                                    <div class="alert alert-danger" role="alert">Passwords don't match
                                    </div>
                                </div>
                                <div *ngIf="resetPasswordForm.errors[ 'server_error' ]">
                                    <div class="alert alert-danger" role="alert">
                                        {{ resetPasswordForm.errors['server_error'] }}
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group mt-4">
                                <button type="submit" class="btn btn-primary btn-block">
                                    Confirm reset password
                                </button>
                            </div>
                            <div class="form-group mt-3">
                                Or you can return to <a routerLink="/login">Sign in</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <strong>AbaQus Field</strong> - Copyright 2019
        </div>
    </div>
    <abq-spinner-card [show]="busy"></abq-spinner-card>
</div>
<abq-message-modal [protected]="'static'"></abq-message-modal>
