import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { IndicationService } from '@shared/services/http/indication/indication.service';
import { IndicationResponse } from '@shared/services/http/indication/indication-response';

@Injectable({
    providedIn: 'root'
})
export class IndicationObtainerService {

    constructor(private indicationService: IndicationService) {
    }

    public obtain$(): Observable<IndicationResponse> {
        return this.indicationService.obtainList$().pipe(
            map((response: IndicationResponse) => this.manageSuccess(response)),
            catchError((error: HttpErrorResponse) => this.generateError(error))
        );
    }

    private manageSuccess(response: IndicationResponse): IndicationResponse {
        if (response.success) {
            return response;
        } else {
            return this.createOneError(
                'Error obtaining treatments: ' + response.errors);
        }
    }

    private generateError(error: HttpErrorResponse): Observable<IndicationResponse> {
        if (error.error?.errors) {
            return this.createObservableOneError(
                'Request error obtaining indications: ' + error.error.errors);
        } else {
            return this.createObservableOneError(
                'Unexpected request error obtaining indications: ' + error.message);
        }
    }

    private createObservableOneError(text: string): Observable<IndicationResponse> {
        return of(this.createOneError(text));
    }

    private createOneError(text: string): IndicationResponse {
        return new IndicationResponse({
                success: false,
                errors: text,
                indications: [],
                form_errors: []
            }
        );
    }
}
