import { GeneralDTO, GeneralResponse } from '@shared/services/http/general-response/general-response';

export class GetTrialProtocolOriginatorsResponse extends GeneralResponse {

    trialProtocolOriginators: string[];

    constructor(getTrialProtocolOriginatorsDTO: GetTrialProtocolOriginatorsDTO) {
        super(getTrialProtocolOriginatorsDTO);
        this.trialProtocolOriginators = getTrialProtocolOriginatorsDTO.trial_protocol_originators;
    }
}

export interface GetTrialProtocolOriginatorsDTO extends GeneralDTO {
    trial_protocol_originators: string[];
}

