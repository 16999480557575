import { ArraySort } from '../../../../abq-common/array-sort';

export class SavedFilterFieldValues {
    private readonly name: string;
    public all: {id: number, name: string}[];
    public selected: string[];

    constructor(name: string, all?: {id: number, name: string}[], selected?: string[]) {
        this.name = name;
        this.all = all ?? [];
        this.selected = selected ?? [];
    }

    public addValue(id: number, name: string): void {
        this.all.push({id, name});
    }

    public sort(): void {
        this.all.sort(new ArraySort().dynamicSort('name'));
    }

    public getAll(): {id: number, name: string}[] {
        return this.all;
    }

    public getSelected(): string[] {
        return this.selected;
    }

    public addSelected(value: any): void {
        this.selected.push(value);
    }

    public removeSelected(value: string): void {
        const elemIndex = this.selected.findIndex((elem) => elem === value);
        if (elemIndex >= 0) {
            this.selected.splice(elemIndex, 1);
        }
    }

    public getName(): string {
        return this.name;
    }

    public setSelectedValues(values: string[]): void {
        this.selected = values;
    }
}
