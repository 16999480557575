<div [ngClass]="{ 'd-flex flex-column': showVertical }">
    <span *ngIf="allElementsSelected" class="badge badge-primary mx-1 mb-1 font-size-1 cursor-default">
        <i class="fa {{ icon }}"></i>
        All
    </span>
    <span *ngFor="let element of elements" class="badge badge-primary mx-1 mb-1 font-size-1 cursor-default">
        <i *ngIf="icon" class="fa {{ icon }}"></i>
        {{ getElementName(element) }}
        <i *ngIf="deletable" class="fa fa-times-circle cursor-pointer"
        (click)="remove(element)"></i>
    </span>
</div>
