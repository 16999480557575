import { HttpErrorResponse } from '@angular/common/http';
import { OrganismCodeService } from '@shared/services/http/organism-code/organism-code.service';
import { OrganismCodesUpdater } from './organism-codes-updater';
import { OrganismCodeResponse } from '@shared/services/http/organism-code/organism-code-response';
import { Indication } from '@shared/models/indication';

export class OrganismCodesObtainer {
    constructor(
        private organismCodeService: OrganismCodeService,
        private organismCodesUpdater: OrganismCodesUpdater) {
    }

    public obtainOrganismCodes(crop: number, indication: Indication) {
        this.organismCodeService.obtainList$(crop, indication).subscribe({
            next: this.manageObtainOrganismCodesResponse.bind(this),
            error: this.manageObtainOrganismCodesErrors.bind(this)
        });
    }

    private manageObtainOrganismCodesResponse(response: OrganismCodeResponse) {
        if (response.success) {
            this.organismCodesUpdater.updateOrganismCodes(response.organismCodes);
        } else {
            console.log('Error received obtaining organism codes: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageObtainOrganismCodesErrors(error: HttpErrorResponse) {
        console.log('Unexpected error received obtaining organism codes: ' + JSON.stringify(error));
    }
}
