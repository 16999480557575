import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageModalComponent } from '../../abq-common/message-modal/message-modal.component';
import { SpinnerModalComponent } from '../../abq-common/spinner-modal/spinner-modal.component';
import { Router } from '@angular/router';
import { MessageModalMode, MessageModalOptions } from '../../abq-common/message-modal/message-modal-types';
import { ResetPasswordService } from './reset-password.service';
import { ResetPasswordResponse } from './reset-password-types';

@Component({
    selector: 'abq-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    public submitted = false;
    public resetForm: UntypedFormGroup;

    @ViewChild(MessageModalComponent) modalComponent: MessageModalComponent;
    @ViewChild(SpinnerModalComponent) spinnerModalComponent: SpinnerModalComponent;

    constructor(protected formBuilder: UntypedFormBuilder,
                private resetPasswordService: ResetPasswordService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.resetForm = this.formBuilder.group({
            email: ['', [Validators.required]]
        });
    }

    // Convenience getter for easy access to form fields.
    get formCtr() {
        return this.resetForm.controls;
    }

    public resetPassword() {
        this.submitted = true;
        if (this.resetForm.invalid === false) {
            this.spinnerModalComponent.show();
            this.resetPasswordService.resetPassword$(this.formCtr.email.value)
                .subscribe({
                    next: this.manageResetPasswordResponse.bind(this, this.formCtr.email.value),
                    error: this.manageResetPasswordError.bind(this)
                });
        }
    }

    private manageResetPasswordResponse(email: string, resetPasswordResponse: ResetPasswordResponse) {
        if (resetPasswordResponse.success) {
            this.spinnerModalComponent.hide();
            const dialogOptions: MessageModalOptions = {
                title: 'Request processed',
                text: 'An email to your ' + email + ' address has been sent. Follow the instructions in it to ' +
                    'continue the password reset process. ',
                mode: MessageModalMode.CONTINUE,
                hideTimesButton: true,
                continueAction: () => {
                    // noinspection JSIgnoredPromiseFromCall
                    this.router.navigate(['/login']);
                }
            };
            this.modalComponent.show(dialogOptions);
        } else {
            this.manageErrorResponse(resetPasswordResponse);
        }
    }

    private manageResetPasswordError(error: any) {
        this.manageErrorResponse(error.error);
    }

    private manageErrorResponse(resetPasswordResponse: any) {
        this.spinnerModalComponent.hide();
        if (resetPasswordResponse.form_errors) {
            for (const field in resetPasswordResponse.form_errors) {
                if (resetPasswordResponse.form_errors.hasOwnProperty(field)) {
                    const control = this.resetForm.get(field);
                    if (control) {
                        control.markAsTouched();
                        control.setErrors({server_error: resetPasswordResponse.form_errors[field]});
                    } else {
                        this.resetForm.markAsTouched();
                        this.resetForm.setErrors({server_error: resetPasswordResponse.form_errors[field]});
                    }
                }
            }
        } else if (resetPasswordResponse.errors) {
            this.resetForm.markAsTouched();
            this.resetForm.setErrors({server_error: resetPasswordResponse.errors});
        }
    }
}
