export const environment = {
    production: true,
    // speadApiUrl: 'https://app-qa.roqs.basf.net/radar_api',
    speadApiUrl: 'https://app.roqs.basf.net/radar_api',
    speadApiMaxNumTrialsAllowed: 250,
    treatmentInfoDisabled: false,
    url: 'https://abaqus-prepro.tecnalia.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
