import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[abqTooltip]'
})
export class TooltipDirective {

    @Input() set abqTooltip(value: string) {
        this.el.nativeElement.title = value;
        $(this.el.nativeElement).tooltip({trigger: 'hover', delay: {hide: 0, show: 100}, placement: 'bottom'});
    }

    @Input() set abqTooltipDotted(value: boolean) {
        if (value) {
            this.el.nativeElement.innerHTML = '<abbr title="">' + this.el.nativeElement.innerHTML + '</abbr>';
        }
    }

    constructor(private el: ElementRef) {
    }
}
