export class ListSelection {

    private selectedElements: number[];
    private allSelected: boolean;

    constructor(selectedElements?: number[], allSelected?: boolean) {
        this.selectedElements = selectedElements ?? [];
        this.allSelected = allSelected ?? false;
    }

    public selectElement(id: number): void {
        if (!this.selectedElements.includes(id)) {
          this.selectedElements.push(id);
          this.allSelected = false;
        }
    }

    public selectElements(ids: number[]): void {
        for (const id of ids) {
            this.selectElement(id);
        }
    }

    public clear(): void {
        this.selectedElements = [];
        this.allSelected = false;
    }

    public unselectElement(id: number): void {
        const found = this.selectedElements.includes(id);
        if (found) {
            this.selectedElements = this.selectedElements.filter(selected => selected !== id);
            this.allSelected = false;
        }
    }

    public isElementSelected(id: number): boolean {
        return this.selectedElements.includes(id);
    }

    public toggleElement(id: number): void {
        if (this.isElementSelected(id)) {
            this.unselectElement(id);
        } else {
            this.selectElement(id);
        }
    }

    public toggleAll(): void {
        this.allSelected = !this.allSelected;
        this.selectedElements = [];
    }

    public selectAll(allSelected: boolean): void {
        this.allSelected = allSelected;
        this.selectedElements = [];
    }

    public isAllSelected(): boolean {
        return this.allSelected;
    }

    public getSelection(): number[] {
        return this.selectedElements;
    }
}
