import { IndicationList } from '@shared/models/indication-list';
import { FilterField } from '../../logged/abq-common/filter-field';
import { FilterFieldId, FILTERS } from './filter-selection.constants';
import { TrialDTO } from '@shared/services/http/trial/trial-dto';

export class TrialFilterExtractor {

    private indicationList;

    constructor() {
        this.indicationList = new IndicationList();
    }

    public getFieldsWithValues(
        trials: TrialDTO[], filteredTrials: TrialDTO[], appliedFilters: FilterField[], shownFilters: FilterFieldId[]
    ): FilterField[] {
        const trialFilterFields: FilterField[] = this.defineTrialFilterFields(shownFilters);
        this.addTrialsValuesToFiltersValues(trials, filteredTrials, trialFilterFields, appliedFilters);
        return trialFilterFields;
    }

    public getFields(shownFilters: FilterFieldId[]): FilterField[] {
        return this.defineTrialFilterFields(shownFilters);
    }

    public getBackendName(fieldId: FilterFieldId): string | null {
        const filterField = FILTERS.find((elem) => elem.id === fieldId);
        if (filterField) {
            return filterField.backendName;
        }
        return null;
    }

    private addTrialsValuesToFiltersValues(
        trials: TrialDTO[], filteredTrials: TrialDTO[], trialFilterFields: FilterField[], appliedFilters: FilterField[]
    ) {
        for (let i = 0; i < trials.length; i += 1) {
            const trial = trials[i];
            for (const trialFilter of trialFilterFields) {
                if (this.hasFilterValueToBeShown(trial, filteredTrials, appliedFilters, trialFilter.name)) {
                    const values: string[] = this.getFilterFieldValues(trialFilter.id, trial);
                    trialFilter.values = trialFilter.values.concat(values);
                    trialFilter.values =
                        trialFilter.values.filter((item, index) => trialFilter.values.indexOf(item) === index);
                }
                trialFilter.filteredValues = Object.assign([], trialFilter.values);
            }
        }
        for (const trialFilter of trialFilterFields) {
            trialFilter.values.sort();
            trialFilter.filteredValues.sort();
        }
    }

    public addTrialValues(
        trial: TrialDTO, filteredTrials: TrialDTO[], appliedFilters: FilterField[], shownFilters: FilterFieldId[]) {
        const trialFilterFields: FilterField[] = this.defineTrialFilterFields(shownFilters);
        this.addTrialsValuesToFiltersValues([trial], filteredTrials, trialFilterFields, appliedFilters);
    }

    public getFilterFieldValues(id: number, trial: TrialDTO): string [] {
        let values: string[];
        if (id === FilterFieldId.Indication) {
            values = [this.getIndicationCode(trial.indication)];
        } else if (id === FilterFieldId.Use) {
            values = [trial.use];
        } else if (id === FilterFieldId.Protocol) {
            values = [trial.protocol];
        } else if (id === FilterFieldId.Crop) {
            values = trial.crops;
        } else if (id === FilterFieldId.Organism) {
            values = trial.organism_codes.map((elem) => elem.name);
        } else if (id === FilterFieldId.Site) {
            values = [trial.place];
        } else if (id === FilterFieldId.Number) {
            values = [trial.number];
        } else if (id === FilterFieldId.Year) {
            values = [trial.year.toString()];
        } else if (id === FilterFieldId.Country) {
            values = [trial.country];
        } else if (id === FilterFieldId.Type) {
            values = [trial.group];
        } else if (id === FilterFieldId.OriginCountry) {
            values = [trial.code.split('-')[3]];
        } else if (id === FilterFieldId.Version) {
            values = [trial.code.split('-')[5]];
        } else if (id === FilterFieldId.Revision) {
            values = [trial.code.split('-')[6]];
        } else if (id === FilterFieldId.Cooperator) {
            values = [trial.cooperator];
        } else if (id === FilterFieldId.ProtocolOriginator) {
            values = [trial.protocol_originator];
        } else if (id === FilterFieldId.TrialResearcher) {
            values = [trial.trial_researcher];
        } else if (id === FilterFieldId.StudyReviewer) {
            values = [trial.study_reviewer];
        } else if (id === FilterFieldId.Team) {
            values = [trial.owner_group];
        } else {
            values = [];
        }
        return values;
    }

    private hasFilterValueToBeShown(
        trial: TrialDTO, filteredTrials: TrialDTO[], appliedFilters: FilterField[], filterName: string) {
        if (filteredTrials.find((elem) => elem.id === trial.id)) {
            return true;
        } else {
            const appliedFilter = appliedFilters.find((obj) => obj.name === filterName);
            if (appliedFilter.values.length > 0) {
                return true;
            }
        }
        return false;
    }

    private getIndicationCode(indicationNumber: number): string {
        const code = this.indicationList.getCodeFromId(indicationNumber);
        return code || 'None';
    }

    private defineTrialFilterFields(shownFilters: FilterFieldId[]): FilterField[] {
        const trialFilterFields = [];

        shownFilters.forEach(filter => {
            trialFilterFields.push(Object.assign({}, FILTERS.find(f => f.id === filter)));
        });
        return trialFilterFields;
    }
}
