import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    GetCountriesResponse,
    GetDateFormatsResponse,
    GetOrganizationsResponse,
    GetTeamsResponse
} from './user-catalog-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserCatalogService {

    private urlGetOrganizations = '/abaqus/rest_api/webapp/info/organization_codes/';
    private urlGetCountries = '/abaqus/rest_api/webapp/info/countries/';
    private urlGetDateFormats = '/abaqus/rest_api/webapp/info/date_formats/';
    private urlGetTeams = '/abaqus/rest_api/webapp/info/teams/';

    constructor(private http: HttpClient) {
    }

    public getOrganizations$(): Observable<GetOrganizationsResponse> {
        return this.http.get<GetOrganizationsResponse>(this.urlGetOrganizations);
    }

    public getCountries$(): Observable<GetCountriesResponse> {
        return this.http.get<GetCountriesResponse>(this.urlGetCountries);
    }

    public getDateFormats$(): Observable<GetDateFormatsResponse> {
        return this.http.get<GetDateFormatsResponse>(this.urlGetDateFormats);
    }

    public getTeams$(): Observable<GetTeamsResponse> {
        return this.http.get<GetTeamsResponse>(this.urlGetTeams);
    }
}

