import { AfterViewInit, Component } from '@angular/core';

@Component({
    selector: 'abq-auto-close',
    templateUrl: './logged-shown.component.html',
    styleUrls: ['./logged-shown.component.scss']
})
export class LoggedShownComponent implements AfterViewInit {
    public ngAfterViewInit(): void {
        window.close();
    }
}
