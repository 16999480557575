import { GeneralDTO, GeneralResponse } from '@shared/services/http/general-response/general-response';

export class GetTrialCooperatorsResponse extends GeneralResponse {

    trialCooperators: string[];

    constructor(getTrialCooperatorsDTO: GetTrialCooperatorsDTO) {
        super(getTrialCooperatorsDTO);
        this.trialCooperators = getTrialCooperatorsDTO.trial_cooperators;
    }
}

export interface GetTrialCooperatorsDTO extends GeneralDTO {
    trial_cooperators: string[];
}
