export enum FilterFieldId {
    Team,
    Use,
    Crop,
    Organism,
    Type,
    Indication,
    Year,
    OriginCountry,
    Protocol,
    Version,
    Revision,
    Country,
    Site,
    Number,
    Cooperator,
    ProtocolOriginator,
    TrialResearcher,
    StudyReviewer,
    End
}

export const FILTERS = [
    {
        id: FilterFieldId.Use,
        name: 'Use',
        backendName: 'use',
        icon: 'fa-toolbox',
        column: 7,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Crop,
        name: 'Crop',
        backendName: 'crop_type',
        icon: 'fa-leaf',
        column: 11,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Organism,
        name: 'Organism',
        backendName: 'organism_code',
        icon: 'fa-bug',
        column: 12,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Cooperator, // Hidden column
        name: 'Cooperator',
        backendName: 'cooperator',
        icon: 'fa-handshake',
        column: 21,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.ProtocolOriginator, // Hidden column
        name: 'Protocol Originator',
        backendName: 'protocol_originator',
        icon: 'fa-share-square',
        column: 22,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.TrialResearcher, // Hidden column
        name: 'Trial Researcher',
        backendName: 'trial_researcher',
        icon: 'fa-microscope',
        column: 23,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.StudyReviewer, // Hidden column
        name: 'Study Reviewer',
        backendName: 'study_reviewer',
        icon: 'fa-search',
        column: 24,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Type,
        name: 'Type',
        backendName: 'type',
        icon: 'fa-stream',
        column: 2,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Indication,
        name: 'Indication',
        backendName: 'indication',
        icon: 'fa-prescription-bottle',
        column: 3,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Year,
        name: 'Year',
        backendName: 'year',
        icon: 'fa-calendar-alt',
        column: 4,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.OriginCountry, // Hidden column.
        name: 'Origin Country',
        backendName: 'origin_country',
        icon: 'fa-clone',
        column: 15,
        // prefix: 'origin-',
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Protocol, // Hidden column.
        name: 'Protocol',
        backendName: 'protocol',
        icon: 'fa-book',
        column: 16,
        // prefix: 'proto-',
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Version, // Hidden column.
        name: 'Version',
        backendName: 'version',
        icon: 'fa-clipboard-list',
        column: 17,
        // prefix: 'version-',
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Revision, // Hidden column.
        name: 'Revision',
        backendName: 'revision',
        icon: 'fa-tag',
        column: 18,
        // prefix: 'revision-',
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Country,
        name: 'Country',
        backendName: 'country',
        icon: 'fa-globe-africa',
        column: 5,
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Site, // Hidden column.
        name: 'Site',
        backendName: 'site',
        icon: 'fa-map-marker-alt',
        column: 19,
        // prefix: 'site-',
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Number, // Hidden column
        name: 'Number',
        backendName: 'number',
        icon: 'fa-hashtag',
        column: 20,
        // prefix: 'number-',
        values: [],
        filteredValues: [],
        show: true
    },
    {
        id: FilterFieldId.Team, // Hidden column
        name: 'Team',
        backendName: 'team',
        icon: 'fa-users',
        column: 13,
        // prefix: 'number-',
        values: [],
        filteredValues: [],
        show: true
    }
];
