import { TrialCatalogService } from '@shared/services/http/trial-catalog/trial-catalog.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TrialSitesObtainerUpdater } from './trial-sites-obtainer-updater';
import { GetTrialSitesResponse } from '@shared/services/http/trial-catalog/get-trial-sites-response';

export class TrialSitesObtainer {
    constructor(
        private trialService: TrialCatalogService,
        private trialSitesObtainerUpdater: TrialSitesObtainerUpdater) {
    }

    public obtainTrialSites() {
        this.trialService.getTrialSites$().subscribe({
            next: this.manageObtainTrialSitesResponse.bind(this),
            error: this.manageObtainTrialSitesErrors.bind(this)
        });
    }

    private manageObtainTrialSitesResponse(response: GetTrialSitesResponse) {
        if (response.success) {
            this.trialSitesObtainerUpdater.updateGetTrialSitesResponse(response.trialSites);
        } else {
            console.log('Error received obtaining trial sites: ' + response.errors);
        }
    }

    // noinspection JSMethodCanBeStatic
    private manageObtainTrialSitesErrors(error: HttpErrorResponse) {
        console.log('Unexpected error received obtaining trial sites: ' + JSON.stringify(error));
    }
}
