export enum Months {
    JANUARY, FEBRUARY, MARCH, APRIL, MAY, JUNE,
    JULY, AUGUST, SEPTEMBER, OCTOBER, NOVEMBER, DECEMBER
}

export class TrialYearListGenerator {
    public getNumberList(): number[] {
        const years: number[] = [];
        const now: Date = new Date();
        let nextYear: number = now.getFullYear();
        if (now.getMonth() >= Months.AUGUST) {
            nextYear += 1;
        }
        for (let j: number = nextYear; j >= 1930; j -= 1) {
            years.push(j);
        }
        return years;
    }

    public getStringList(): string[] {
        const yearsIn: number[] = this.getNumberList();
        return yearsIn.map((year: number) => year.toString());
    }
}
